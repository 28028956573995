import React from "react";
import { Flex, Text } from "@radix-ui/themes";
import { CircleCheckBig, CircleSlash, CircleAlert } from "lucide-react";

const BADGE_STATES = {
  error: {
    icon: CircleAlert,
    text: "Error",
    color: "var(--amber-11)",
  },
  yes: {
    icon: CircleCheckBig,
    text: "Yes",
    color: "var(--green-9)",
  },
  no: {
    icon: CircleSlash,
    text: "No",
    color: "var(--red-9)",
  },
};

const DetectedBadge = ({ detected, error }) => {
  const state = error ? "error" : detected ? "yes" : "no";
  const { icon: Icon, text, color } = BADGE_STATES[state];

  return (
    <Flex className="detected-badge" align="center" gap="6px" style={{ color }}>
      <Icon size={12} strokeWidth={2.5} style={{ marginTop: 1 }} />
      <Text weight="medium">{text}</Text>
    </Flex>
  );
};

export default DetectedBadge;
