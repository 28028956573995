import { Flex, Tooltip, Badge, Link, Text } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import { ClockAlert, ExternalLink } from "lucide-react";

import { MONITOR_FREQUENCY_HOURS } from "./context/MonitorContext";
import { toTimeAgo } from "./utils";

const Status = ({ detected, error }) => {
  const color = error ? "gray" : detected ? "green" : "red";

  return (
    <Flex
      align="center"
      justify="center"
      style={{
        border: `1px solid var(--${color}-7)`,
        borderRadius: 9999,
        height: 12,
        width: 12,
      }}
    >
      <Flex
        style={{
          height: 5,
          width: 5,
          borderRadius: 9999,
          backgroundColor: `var(--${color}-9)`,
          marginBottom: 0.5,
        }}
      />
    </Flex>
  );
};

const MonitorBadge = ({ result, account }) => {
  const isOutdated =
    new Date(result.evaluated_at).getTime() <
    Date.now() - MONITOR_FREQUENCY_HOURS * 60 * 60 * 1000;

  return (
    <Flex align="center" gap="2">
      <Tooltip
        content={
          <Text style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            View full result
            <ExternalLink size={12} />
          </Text>
        }
      >
        <Flex align="center" gap="2" style={{ width: "fit-content" }}>
          <Status detected={result.detected} error={isOutdated} />
          <Link
            size="2"
            underline="always"
            href={`/monitors/${result.monitor_id}#${account.id}`}
            target="_blank"
            rel="noopener noreferrer"
            wrap="nowrap"
            style={{ color: isOutdated ? gray.gray10 : gray.gray12 }}
          >
            {result.label}
          </Link>
        </Flex>
      </Tooltip>
      {isOutdated && (
        <Tooltip
          content={`Outdated: last updated ${toTimeAgo(
            new Date(result.evaluated_at).getTime(),
            true
          )}`}
        >
          <Badge color="gray" size="1">
            <ClockAlert size={12} style={{ margin: "2px 0" }} />
          </Badge>
        </Tooltip>
      )}
    </Flex>
  );
};

export default MonitorBadge;
