import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { useAuth } from "./context/AuthContext";
import { useDevTools, DevToolsProvider } from "./context/DevToolsContext.js";
import { AccountProvider } from "./context/AccountContext";
import { MonitorProvider } from "./context/MonitorContext";
import RegistrationPage from "./RegistrationPage";
import LoginPage from "./LoginPage";
import Nav from "./Nav";
import DevToolsPage from "./DevToolsPage.js";
import { AllAccountsList, WorkingList, MyBookList } from "./AccountList";
import IssueList from "./IssueList.js";
import MonitorCreator from "./MonitorCreator";
import MonitorList from "./MonitorList";
import MonitorDetail from "./MonitorDetail";
import BG from "./assets/bg.png";
import "./App.css";

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const Layout = () => (
  <div className="App" style={{ backgroundImage: `url(${BG})` }}>
    <Nav />
    <div className="content">
      <main>
        <Outlet />
      </main>
    </div>
    <Toaster />
  </div>
);

const AuthenticatedRoutes = () => {
  const DevTools = useDevTools();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="accounts/issues" replace />} />

        {/* <Route path="working-list">
          <Route index element={<Navigate to="all" replace />} />
          <Route path=":sectionId" element={<WorkingList />} />
        </Route> */}

        <Route path="accounts">
          <Route index element={<Navigate to="issues" replace />} />
          <Route path="issues" element={<IssueList />} />
          <Route path="my-book" element={<MyBookList />} />
          <Route path="all" element={<AllAccountsList />} />
        </Route>

        <Route path="monitors">
          <Route index element={<Navigate to="create" replace />} />
          <Route path="create" element={<MonitorCreator />} />
          <Route path="all" element={<MonitorList />} />
          <Route path=":id" element={<MonitorDetail />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />

        {DevTools.isEnabled && (
          <Route path="/dev-tools" element={<DevToolsPage />} />
        )}
      </Route>
    </Routes>
  );
};

const AuthenticatedApp = () => {
  return (
    <DevToolsProvider>
      <AccountProvider>
        <MonitorProvider>
          <AuthenticatedRoutes />
        </MonitorProvider>
      </AccountProvider>
    </DevToolsProvider>
  );
};

const PublicApp = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    {/* <Route path="/register" element={<RegistrationPage />} /> */}
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedApp /> : <PublicApp />}
    </BrowserRouter>
  );
}

export default App;
