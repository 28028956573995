import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Flex,
  Box,
  Button,
  DropdownMenu,
  Popover,
  Text,
  TextField,
  ScrollArea,
  Checkbox,
  Separator,
  Tooltip,
} from "@radix-ui/themes";
import {
  ClientSideRowModelModule,
  ModuleRegistry,
  ValidationModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  RowSelectionModule,
  CustomFilterModule,
  ColumnAutoSizeModule,
  ColumnApiModule,
  RowStyleModule,
  EventApiModule,
  CellStyleModule,
} from "ag-grid-community";
import {
  ArrowUp,
  ArrowDown,
  LayoutGrid,
  RotateCcw,
  Search,
  GripVertical,
  XCircle,
  EllipsisVertical,
  ChevronsUpDown,
} from "lucide-react";
import "./DataGrid.css";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { debounce } from "./utils";

// Register needed modules
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ValidationModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  RowSelectionModule,
  CustomFilterModule,
  ColumnAutoSizeModule,
  ColumnApiModule,
  RowStyleModule,
  EventApiModule,
  CellStyleModule,
]);

// Custom Header Component for AG-Grid
const HeaderMenu = (props) => {
  const [isColumnChooserOpen, setIsColumnChooserOpen] = useState(false);
  const menuRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentSort, setCurrentSort] = useState(props.column.getSort());

  // Track sort changes
  useEffect(() => {
    const onSortChanged = () => {
      setCurrentSort(props.column.getSort());
    };

    if (props.api) {
      props.api.addEventListener("sortChanged", onSortChanged);
    }

    return () => {
      if (props.api) {
        props.api.removeEventListener("sortChanged", onSortChanged);
      }
    };
  }, [props.api, props.column]);

  // Close column chooser when dropdown closes
  useEffect(() => {
    if (!isDropdownOpen && isColumnChooserOpen) {
      setIsColumnChooserOpen(false);
    }
  }, [isDropdownOpen, isColumnChooserOpen]);

  // This gets called when we click the menu button
  const onMenuClicked = (e) => {
    e.stopPropagation();
    menuRef.current?.click();
  };

  // Sort ascending
  const onSortAscending = () => {
    props.api.applyColumnState({
      state: [
        {
          colId: props.column.getColId(),
          sort: "asc",
        },
      ],
      defaultState: {
        sort: null,
      },
    });
  };

  // Sort descending
  const onSortDescending = () => {
    props.api.applyColumnState({
      state: [
        {
          colId: props.column.getColId(),
          sort: "desc",
        },
      ],
      defaultState: {
        sort: null,
      },
    });
  };

  // Clear sorting
  const onClearSort = () => {
    props.api.applyColumnState({
      defaultState: {
        sort: null,
      },
    });
  };

  // Column autosize
  const onAutoSizeThisColumn = () => {
    props.api.autoSizeColumn(props.column);
  };

  // All columns autosize
  const onAutoSizeAllColumns = () => {
    props.api.autoSizeAllColumns();
  };

  // Reset columns to default
  const onResetColumns = () => {
    props.api.resetColumnState();
  };

  return (
    <Flex onClick={onMenuClicked} mr="2">
      <DropdownMenu.Root onOpenChange={setIsDropdownOpen}>
        <DropdownMenu.Trigger ref={menuRef}>
          <Button
            variant="ghost"
            size="1"
            color="gray"
            style={{ padding: "2px" }}
          >
            <EllipsisVertical size={14} color="var(--gray-10)" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {props.enableSorting !== false && (
            <>
              {currentSort !== "asc" && (
                <DropdownMenu.Item onSelect={onSortAscending}>
                  <Flex align="center" gap="2">
                    <ArrowUp size={14} style={{ opacity: 0.45 }} />
                    <Text>Sort ascending</Text>
                  </Flex>
                </DropdownMenu.Item>
              )}

              {currentSort !== "desc" && (
                <DropdownMenu.Item onSelect={onSortDescending}>
                  <Flex align="center" gap="2">
                    <ArrowDown size={14} style={{ opacity: 0.45 }} />
                    <Text>Sort descending</Text>
                  </Flex>
                </DropdownMenu.Item>
              )}

              {currentSort && (
                <DropdownMenu.Item onSelect={onClearSort}>
                  <Flex align="center" gap="2">
                    <ChevronsUpDown size={14} style={{ opacity: 0.45 }} />
                    <Text>Clear sort</Text>
                  </Flex>
                </DropdownMenu.Item>
              )}

              <DropdownMenu.Separator />
            </>
          )}

          {/* <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger>
              <Flex align="center" gap="2">
                <Pin size={16} />
                <Text>Pin Column</Text>
              </Flex>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent>
              <DropdownMenu.Item
                onSelect={() =>
                  props.api.setColumnPinned(props.column.getColId(), "left")
                }
              >
                Left
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onSelect={() =>
                  props.api.setColumnPinned(props.column.getColId(), "right")
                }
              >
                Right
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onSelect={() =>
                  props.api.setColumnPinned(props.column.getColId(), null)
                }
              >
                Unpin
              </DropdownMenu.Item>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>

          <DropdownMenu.Separator /> */}

          {/* <DropdownMenu.Item onSelect={onAutoSizeThisColumn}>
            <Text>Autosize This Column</Text>
          </DropdownMenu.Item>

          <DropdownMenu.Item onSelect={onAutoSizeAllColumns}>
            <Text>Autosize All Columns</Text>
          </DropdownMenu.Item>

          <DropdownMenu.Separator /> */}

          <Popover.Root
            open={isColumnChooserOpen}
            onOpenChange={setIsColumnChooserOpen}
          >
            <Popover.Trigger asChild>
              <DropdownMenu.Item
                onSelect={(e) => {
                  e.preventDefault();
                  setIsColumnChooserOpen(true);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsColumnChooserOpen(true);
                }}
              >
                <Flex align="center" gap="2">
                  <LayoutGrid size={14} style={{ opacity: 0.45 }} />
                  <Text>Choose columns</Text>
                </Flex>
              </DropdownMenu.Item>
            </Popover.Trigger>
            <Popover.Content
              className="column-chooser-popover"
              sideOffset={5}
              onInteractOutside={(e) => {
                e.preventDefault();
              }}
              onEscapeKeyDown={() => setIsColumnChooserOpen(false)}
            >
              <ColumnChooser
                api={props.api}
                onClose={() => setIsColumnChooserOpen(false)}
              />
            </Popover.Content>
          </Popover.Root>

          <DropdownMenu.Item onSelect={onResetColumns}>
            <Flex align="center" gap="2">
              <RotateCcw size={14} style={{ opacity: 0.45 }} />
              <Text>Reset columns</Text>
            </Flex>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </Flex>
  );
};

// Sortable column item component
const SortableColumnItem = ({ column, onVisibilityChange }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: column.colId,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="column-chooser-item">
      <Flex align="center" gap="2">
        <div className="drag-handle" {...attributes} {...listeners}>
          <GripVertical size={14} />
        </div>
        <Checkbox
          checked={column.visible}
          onCheckedChange={(checked) =>
            onVisibilityChange(column.colId, checked)
          }
        />
        <Text size="2">{column.headerName}</Text>
      </Flex>
    </div>
  );
};

// Updated Column chooser component with drag and drop functionality
const ColumnChooser = ({ api, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [columnStates, setColumnStates] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Initial load of columns
  useEffect(() => {
    if (api) {
      // Get all columns
      const allColumns = api.getColumns() || [];
      const order = allColumns.map((col) => col.getColId());
      setColumnOrder(order);

      // Initialize column states
      const initialStates = {};
      allColumns.forEach((col) => {
        initialStates[col.getColId()] = {
          visible: col.isVisible(),
          headerName: col.getColDef().headerName || col.getColDef().field,
        };
      });

      setColumnStates(initialStates);
    }
  }, [api]);

  const filteredColumns = useMemo(() => {
    const filtered = Object.entries(columnStates)
      .filter(([colId, state]) => {
        return state.headerName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
      .map(([colId, state]) => ({ colId, ...state }));

    // Sort by the column order
    return columnOrder
      .filter((colId) => filtered.some((col) => col.colId === colId))
      .map((colId) => filtered.find((col) => col.colId === colId));
  }, [columnStates, searchTerm, columnOrder]);

  const handleVisibilityChange = (colId, isVisible) => {
    // Update API
    api.setColumnsVisible([colId], isVisible);

    // Update our local state immediately
    setColumnStates((prev) => ({
      ...prev,
      [colId]: {
        ...prev[colId],
        visible: isVisible,
      },
    }));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      // Get current column order
      const oldIndex = columnOrder.indexOf(active.id);
      const newIndex = columnOrder.indexOf(over.id);

      // Create new column order
      const newColumnOrder = [...columnOrder];
      newColumnOrder.splice(oldIndex, 1);
      newColumnOrder.splice(newIndex, 0, active.id);

      // Update local state
      setColumnOrder(newColumnOrder);

      // Convert column IDs to indexes in the grid
      const allColumns = api.getColumns();
      const fromIndex = allColumns.findIndex(
        (col) => col.getColId() === active.id
      );
      const toIndex = allColumns.findIndex((col) => col.getColId() === over.id);

      // TODO: fix this
      api.moveColumnByIndex(fromIndex + 1, toIndex + 1);
    }
  };

  return (
    <Flex direction="column" className="column-chooser">
      <Flex align="center" justify="between" mb="3">
        <Text weight="medium">Choose columns</Text>
        <Button variant="ghost" color="gray" size="1" onClick={onClose}>
          <XCircle size={14} />
        </Button>
      </Flex>

      <TextField.Root
        placeholder="Search columns..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb="3"
        size="1"
      >
        <TextField.Slot>
          <Search size={14} />
        </TextField.Slot>
      </TextField.Root>

      <Separator size="4" mb="2" />

      <ScrollArea style={{ height: "250px" }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={filteredColumns.map((col) => col.colId)}
            strategy={verticalListSortingStrategy}
          >
            {filteredColumns.map((column) => (
              <SortableColumnItem
                key={column.colId}
                column={column}
                onVisibilityChange={handleVisibilityChange}
              />
            ))}
          </SortableContext>
        </DndContext>
      </ScrollArea>
    </Flex>
  );
};

// Custom header component that combines the regular header with our menu
const CustomHeaderComponent = (props) => {
  const [sortState, setSortState] = useState(props.column.getSort());

  // Listen for sort changes
  useEffect(() => {
    const onSortChanged = () => {
      setSortState(props.column.getSort());
    };

    // Add listener to the grid API
    if (props.api) {
      props.api.addEventListener("sortChanged", onSortChanged);
    }

    // Clean up event listener when unmounting
    return () => {
      if (props.api) {
        props.api.removeEventListener("sortChanged", onSortChanged);
      }
    };
  }, [props.api, props.column]);

  // Handle click on header text to toggle sorting
  const onSortRequested = (e) => {
    e.stopPropagation();

    if (!props.column.getColDef().sortable) return;

    const currentSort = props.column.getSort();
    let nextSort = "asc";

    if (currentSort === "asc") {
      nextSort = "desc";
    } else if (currentSort === "desc") {
      nextSort = null;
    }

    const multiSort = e.shiftKey;

    if (multiSort) {
      // Apply sort to this column without affecting others
      props.api.applyColumnState({
        state: [
          {
            colId: props.column.getColId(),
            sort: nextSort,
          },
        ],
      });
    } else {
      // Apply sort to this column and clear sort on others
      props.api.applyColumnState({
        state: [
          {
            colId: props.column.getColId(),
            sort: nextSort,
          },
        ],
        defaultState: {
          sort: null,
        },
      });
    }
  };

  const SortDirectionIcon = sortState === "asc" ? ArrowUp : ArrowDown;

  return (
    <Flex align="center" justify="between" className="ag-header-cell-label">
      <Flex
        onClick={onSortRequested}
        align="center"
        gap="2"
        style={{
          cursor: props.column.getColDef().sortable ? "pointer" : "default",
          width: "100%",
        }}
      >
        <Text className="ag-header-cell-text">{props.displayName}</Text>
        {sortState && <SortDirectionIcon size={12} strokeWidth={1.5} />}
      </Flex>
      {props.enableMenu && (
        <HeaderMenu
          column={props.column}
          api={props.api}
          enableSorting={props.column.colDef.sortable}
        />
      )}
    </Flex>
  );
};

// Add this new component for bulk actions
const BulkActionBar = ({ selectedRows, actions, api }) => {
  if (!selectedRows || selectedRows.length === 0) return null;

  return (
    <Flex
      py="2"
      px="4"
      gap="4"
      align="center"
      style={{
        borderBottom: "1px solid var(--gray-6)",
        background: "var(--gray-2)",
      }}
    >
      <Text size="1" weight="medium" color="gray">
        {selectedRows.length}{" "}
        {selectedRows.length === 1 ? "account" : "accounts"} selected
      </Text>
      <Flex gap="2">
        {actions.map((action, index) => {
          const ActionButton = (
            <Button
              key={index}
              size="1"
              radius="large"
              variant={action.variant || "soft"}
              color={action.color || "gray"}
              onClick={() => action.handler(selectedRows, api)}
              style={{ cursor: "pointer" }}
            >
              {action.icon && <action.icon size={14} />}
              {action.label}
            </Button>
          );

          return action.tooltip ? (
            <Tooltip
              key={index}
              content={action.tooltip}
              sideOffset={4}
              style={{ width: 248 }}
            >
              {ActionButton}
            </Tooltip>
          ) : (
            ActionButton
          );
        })}
      </Flex>
    </Flex>
  );
};

// New hook for grid configuration persistence
const useGridConfig = (gridRef, storageKey) => {
  const saveConfig = useCallback(() => {
    if (!storageKey || !gridRef.current?.api) return;

    try {
      // Get column state
      const columnState = gridRef.current.api.getColumnState();

      // Get filter model
      const filterModel = gridRef.current.api.getFilterModel();

      // Get sort state from column state
      const sortModel = columnState
        .filter((col) => col.sort)
        .map((col) => ({
          colId: col.colId,
          sort: col.sort,
          sortIndex: col.sortIndex,
        }));

      localStorage.setItem(
        storageKey,
        JSON.stringify({ columnState, filterModel, sortModel })
      );
    } catch (error) {
      console.error("Error saving grid configuration:", error);
    }
  }, [storageKey, gridRef]);

  const loadConfig = useCallback(() => {
    if (!storageKey || !gridRef.current?.api) return;

    try {
      const savedConfig = localStorage.getItem(storageKey);

      if (!savedConfig) {
        gridRef.current.api.resetColumnState();
        gridRef.current.api.setFilterModel(null);
        gridRef.current.api.sizeColumnsToFit();
        return;
      }

      const { columnState, filterModel, sortModel } = JSON.parse(savedConfig);

      if (columnState) {
        // Apply column state (includes width, visibility, order)
        gridRef.current.api.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }

      if (filterModel) {
        gridRef.current.api.setFilterModel(filterModel);
      }

      // Sort model is applied through column state
      if (sortModel && sortModel.length > 0) {
        gridRef.current.api.applyColumnState({
          state: sortModel,
          defaultState: { sort: null },
        });
      }
    } catch (error) {
      console.error("Error loading grid configuration:", error);
    }
  }, [storageKey, gridRef]);

  const resetConfig = useCallback(() => {
    if (!gridRef.current?.api) return;

    // Reset to default
    gridRef.current.api.resetColumnState();
    gridRef.current.api.setFilterModel(null);

    // Clear saved config if storage key exists
    if (storageKey) {
      localStorage.removeItem(storageKey);
    }
  }, [storageKey, gridRef]);

  const debouncedSaveConfig = useMemo(
    () => debounce(saveConfig, 200),
    [saveConfig]
  );

  const setupConfigListeners = useCallback(
    (api) => {
      if (!storageKey || !api) return () => {};

      const configEvents = [
        "columnResized",
        "columnMoved",
        "columnVisible",
        "columnPinned",
        "sortChanged",
        "filterChanged",
      ];

      // Add all event listeners
      configEvents.forEach((eventType) => {
        api.addEventListener(eventType, debouncedSaveConfig);
      });

      // Return cleanup function
      return () => {
        configEvents.forEach((eventType) => {
          api.removeEventListener(eventType, debouncedSaveConfig);
        });
      };
    },
    [storageKey, debouncedSaveConfig]
  );

  return { saveConfig, loadConfig, resetConfig, setupConfigListeners };
};

const DataGrid = ({
  rowData = [],
  columnDefs = [],
  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 100,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      enableMenu: true,
    },
  },
  disableSorting = false,
  onRowClicked,
  domLayout = "normal",
  gridOptions = {},
  animateRows = true,
  className = "",
  style = {},
  height = 600,
  rowSelection = "multiple",
  bulkActions = [],
  onSelectionChanged,
  activeRowId = null,
  configKey = null,
  ...restProps
}) => {
  const gridRef = useRef();
  const [selectedRows, setSelectedRows] = useState([]);
  const [gridReady, setGridReady] = useState(false);

  const { loadConfig, resetConfig, setupConfigListeners } = useGridConfig(
    gridRef,
    configKey
  );

  const themeClass = "ag-theme-custom";

  const containerStyle = useMemo(
    () => ({
      height: typeof height === "number" ? `${height}px` : height,
      width: "100%",
      ...style,
    }),
    [height, style]
  );

  // Set up event listeners for config persistence
  useEffect(() => {
    if (!gridReady || !configKey || !gridRef.current?.api) return;

    // Setup the listeners and get the cleanup function
    const cleanupListeners = setupConfigListeners(gridRef.current.api);

    // Return the cleanup function for useEffect
    return cleanupListeners;
  }, [gridReady, configKey, setupConfigListeners]);

  // Load config or auto size columns
  useEffect(() => {
    if (!gridReady || !gridRef.current?.api) return;

    if (!configKey) return gridRef.current.api.sizeColumnsToFit();

    loadConfig();
  }, [configKey, gridReady, rowData, loadConfig]);

  // Handle selection changes
  const handleSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);

    // Call external handler if provided
    if (onSelectionChanged) {
      onSelectionChanged(selectedData, event);
    }
  };

  // Add row class based on active row id
  const getRowClass = (params) => {
    if (params.data && params.data.id === activeRowId) {
      return "row-active";
    }
    return "";
  };

  // When there's a search term, we should pre-sort the data to maintain search relevance
  useEffect(() => {
    if (gridReady && gridRef.current?.api && disableSorting) {
      // Clear any existing sorts to respect the search relevance order
      gridRef.current.api.applyColumnState({
        defaultState: { sort: null },
      });

      // Prevent automatic sorting when data changes by setting suppressRowDrag to true
      gridRef.current.api.setGridOption("suppressRowDrag", true);
    }
  }, [gridReady, disableSorting, rowData]);

  // Merge default colDef with our resetConfig function
  const mergedDefaultColDef = useMemo(
    () => ({
      ...defaultColDef,
      headerComponentParams: {
        ...(defaultColDef.headerComponentParams || {}),
        resetColumnState: resetConfig,
      },
    }),
    [defaultColDef, resetConfig]
  );

  return (
    <Box className={`data-grid-container ${className}`}>
      <Flex direction="column" className={themeClass} style={containerStyle}>
        {bulkActions.length > 0 && (
          <BulkActionBar
            selectedRows={selectedRows}
            actions={bulkActions}
            api={gridRef.current?.api}
          />
        )}
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={mergedDefaultColDef}
          onGridReady={setGridReady}
          onRowClicked={onRowClicked}
          animateRows={animateRows}
          domLayout={domLayout}
          suppressCellFocus={true}
          suppressRowHoverHighlight={true}
          rowSelection={rowSelection}
          onSelectionChanged={handleSelectionChanged}
          getRowClass={getRowClass}
          {...gridOptions}
          {...restProps}
        />
      </Flex>
    </Box>
  );
};

// Export utility functions
export const createTextColumn = (field, headerName, options = {}) => ({
  field,
  headerName,
  filter: "agTextColumnFilter",
  ...options,
});

export const createNumberColumn = (field, headerName, options = {}) => ({
  field,
  headerName,
  filter: "agNumberColumnFilter",
  ...options,
});

export const createDateColumn = (field, headerName, options = {}) => ({
  field,
  headerName,
  filter: "agDateColumnFilter",
  ...options,
});

export default DataGrid;
