import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Heading, TextField, Flex, Button } from "@radix-ui/themes";
import { EnvelopeClosedIcon, LockClosedIcon } from "@radix-ui/react-icons";

import { useAuth } from "./context/AuthContext";
import LoginBG from "./assets/login-bg.webp";
import ProductLogo from "./assets/product-logo.svg";
import "./LoginPage.css";

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    const isSuccess = await login(email, password);
    if (isSuccess) {
      navigate("/");
      return;
    }

    alert("Login failed");
    setPassword("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <Flex
      className="login-container"
      direction="column"
      align="center"
      style={{ backgroundImage: `url(${LoginBG})` }}
    >
      <a
        href="https://getlateral.ai"
        style={{
          backgroundColor: "#ccc",
          margin: "64px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px 20px",
          borderRadius: "999px",
        }}
      >
        <img
          className="logo"
          src={ProductLogo}
          alt="Lateral"
          draggable={false}
          style={{ height: "24px", flexShrink: 0 }}
        />
      </a>
      <Flex
        className="login-box"
        direction="column"
        mt={{ initial: 0, xs: "16vh" }}
        mb="9"
        p="5"
      >
        <Flex direction="column" width={{ initial: "100%", xs: "280px" }}>
          <Heading mb="5" align="center" style={{ fontWeight: 600 }}>
            Sign in to your account
          </Heading>
          <TextField.Root
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Email"
            size="3"
            mb="4"
          >
            <TextField.Slot>
              <EnvelopeClosedIcon color="gray" height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <TextField.Root
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Password"
            size="3"
            mb="2"
          >
            <TextField.Slot>
              <LockClosedIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <Button
            size="3"
            color="gray"
            highContrast
            onClick={handleLogin}
            mt="5"
          >
            Sign in
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
