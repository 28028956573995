import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Hook that scrolls to an element when its ID matches the URL hash.
 * Example: If URL is "/accounts#123", it will scroll to element with id="123"
 *
 * @param {string} id - Element ID to match against URL hash
 * @returns {boolean} - Whether this element matches current URL hash
 *
 * @example
 * const isScrollTarget = useHashScroll(accountId);
 * return <div id={accountId} className={isScrollTarget ? 'highlighted' : ''} />;
 */
export const useHashScroll = (id) => {
  const location = useLocation();
  const isHashMatch = location.hash === `#${id}`;

  useEffect(() => {
    if (isHashMatch) {
      requestAnimationFrame(() => {
        document.getElementById(id)?.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      });
    }
  }, [isHashMatch, id]);

  return isHashMatch;
};
