import React, { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  init as initFS,
  isInitialized as isFSInitialized,
  FullStory,
} from "@fullstory/browser";
import { AnalyticsBrowser } from "@segment/analytics-next";
import * as Sentry from "@sentry/react";

import { useAuth } from "./context/AuthContext";

const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID;
const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const Analytics = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      initializeFullStory();
      initializeSegment();
      setupClickTracking();
      setupPageViewTracking();
    } else {
      shutdownFullStory();
      shutdownSegment();
    }

    // Cleanup
    return () => {
      removeClickTracking();
      removePageViewTracking();
    };
  }, [user]);

  // FullStory
  const initializeFullStory = () => {
    if (!FULLSTORY_ORG_ID) return;

    initFS({ orgId: FULLSTORY_ORG_ID });
    FullStory("setIdentity", {
      uid: user.id,
      properties: {
        displayName: user.name,
        email: user.email,
        organizationId: user.organization_id,
      },
    });
  };

  const shutdownFullStory = () => {
    if (FullStory && isFSInitialized()) {
      FullStory("shutdown");
    }
  };

  // Segment
  const initializeSegment = () => {
    if (!SEGMENT_WRITE_KEY) return;

    if (!segment.instance.initialized) {
      segment = AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY });
    }

    segment.ready(() => {
      segment.identify(user.id, {
        name: user.name,
        email: user.email,
      });

      segment.group(user.organization_id);
    });
  };

  const shutdownSegment = () => {
    if (segment.instance.initialized) {
      segment.reset();
    }
  };

  // Track click events
  const setupClickTracking = () => {
    document.addEventListener("click", trackClick);
  };

  const removeClickTracking = () => {
    document.removeEventListener("click", trackClick);
  };

  const trackClick = (e) => {
    if (!user) return;

    const element = e.target;
    segment.track("User Click", {
      element_type: element.tagName.toLowerCase(),
      element_id: element.id,
      element_class: element.className,
      element_text: element.innerText?.substring(0, 500) || "",
      path: window.location.pathname,
    });
  };

  // Track page views
  const setupPageViewTracking = () => {
    window.addEventListener("popstate", trackPageView);

    // Store original methods
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // Override history methods
    window.history.pushState = function () {
      originalPushState.apply(this, arguments);
      trackPageView();
    };

    window.history.replaceState = function () {
      originalReplaceState.apply(this, arguments);
      trackPageView();
    };

    // Store the original methods for cleanup
    window._originalHistoryMethods = {
      pushState: originalPushState,
      replaceState: originalReplaceState,
    };
  };

  const removePageViewTracking = () => {
    window.removeEventListener("popstate", trackPageView);

    // Restore original history methods if they were saved
    if (window._originalHistoryMethods) {
      window.history.pushState = window._originalHistoryMethods.pushState;
      window.history.replaceState = window._originalHistoryMethods.replaceState;
      delete window._originalHistoryMethods;
    }
  };

  const trackPageView = () => {
    if (!user) return;

    segment.page({
      path: window.location.pathname,
      title: document.title,
    });
  };

  return null;
};

export const initializeSentry = () => {
  if (!SENTRY_DSN) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });
};

export let segment = {
  identify: () => {},
  track: () => {},
  page: () => {},
  instance: {},
};

export default Analytics;
