import React, { createContext, useCallback, useContext } from "react";
import { useDevTools } from "./DevToolsContext";
import { BASE_URL } from "../App";

const AccountContext = createContext();

export const useAccount = () => {
  return useContext(AccountContext);
};

export const AccountProvider = ({ children }) => {
  const DevTools = useDevTools();

  const getAccounts = useCallback(async (userId) => {
    const url = new URL(`${BASE_URL}/api/v1/accounts`);
    if (userId) {
      url.searchParams.append(
        "book-of-business",
        DevTools.bookOfBusinessUserId || userId
      );
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const accounts = await response.json();
    return accounts.customer_accounts;
  }, []);

  const getAccountsHomeData = useCallback(
    async (userId) => {
      const url = new URL(`${BASE_URL}/api/v1/accounts-home`);
      if (userId) {
        url.searchParams.append(
          "book-of-business",
          DevTools.bookOfBusinessUserId || userId
        );
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const data = await response.json();
      return data.accounts;
    },
    [DevTools.bookOfBusinessUserId]
  );

  const getAccountsNeedingReview = useCallback(async () => {
    const url = new URL(`${BASE_URL}/api/v1/accounts/needs-triage`);
    if (DevTools.bookOfBusinessUserId) {
      url.searchParams.append(
        "book-of-business",
        DevTools.bookOfBusinessUserId
      );
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();
    return data.accounts;
  }, [DevTools.bookOfBusinessUserId]);

  const dismissNeedsAttentionRule = useCallback(async (ruleFlagId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/accounts/needs-attention/dismiss/rule`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          rule_flag_external_id: ruleFlagId,
        }),
      }
    );

    const data = await response.json();
    return data;
  }, []);

  const getWorkingList = useCallback(async () => {
    const url = new URL(`${BASE_URL}/api/v1/accounts/working-list`);
    if (DevTools.bookOfBusinessUserId) {
      url.searchParams.append(
        "book-of-business",
        DevTools.bookOfBusinessUserId
      );
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return await response.json();
  }, [DevTools.bookOfBusinessUserId]);

  const createWorkingListSection = useCallback(
    async (sectionName) => {
      const url = new URL(`${BASE_URL}/api/v1/accounts/working-list/section`);
      if (DevTools.bookOfBusinessUserId) {
        url.searchParams.append(
          "book-of-business",
          DevTools.bookOfBusinessUserId
        );
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          section_name: sectionName,
        }),
      });

      const data = await response.json();
      return data.section_id;
    },
    [DevTools.bookOfBusinessUserId]
  );

  const updateAccountInWorkingList = useCallback(
    async (accountId, sectionId, position) => {
      const url = new URL(`${BASE_URL}/api/v1/accounts/working-list/account`);
      if (DevTools.bookOfBusinessUserId) {
        url.searchParams.append(
          "book-of-business",
          DevTools.bookOfBusinessUserId
        );
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          account_external_id: accountId,
          section_external_id: sectionId,
          position: position,
        }),
      });

      return await response.json();
    },
    [DevTools.bookOfBusinessUserId]
  );

  const createAccountNote = useCallback(async (accountId, note, isPublic) => {
    const url = `${BASE_URL}/api/v1/accounts/${accountId}/notes`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        note,
        is_public: isPublic,
      }),
    });

    return await response.json();
  }, []);

  const getEndUsersForAccount = useCallback(async (accountExternalId) => {
    const url = `${BASE_URL}/api/v1/accounts/${accountExternalId}/end-users`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const data = await response.json();
    return data.end_users;
  }, []);

  const getDraftEmailForMonitorFlag = useCallback(
    async (accountId, recipientId, monitorFlagId) => {
      const url = `${BASE_URL}/api/v1/accounts/draft-email`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          account_id: accountId,
          recipient_id: recipientId,
          monitor_flag_id: monitorFlagId,
        }),
      });
      return await response.json();
    },
    []
  );

  // Mock function to send in-app messages
  const sendInAppMessage = async (
    userId,
    message,
    deliveryMethod = "intercom"
  ) => {
    // Simulate API call delay
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Mock successful response
    return {
      success: true,
      message_id: `msg_${Math.random().toString(36).substr(2, 9)}`,
      recipient: { id: userId },
      sent_at: new Date().toISOString(),
    };
  };

  return (
    <AccountContext.Provider
      value={{
        getAccounts,
        getAccountsHomeData,
        getAccountsNeedingReview,
        dismissNeedsAttentionRule,
        getWorkingList,
        createWorkingListSection,
        updateAccountInWorkingList,
        createAccountNote,
        getEndUsersForAccount,
        getDraftEmailForMonitorFlag,
        sendInAppMessage,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
