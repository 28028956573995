import React from "react";
import { Text, Flex } from "@radix-ui/themes";
import { gray, violet } from "@radix-ui/colors";

const Toolbar = ({
  icon: Icon,
  sectionTitle,
  pageTitle,
  suffixSlot,
  centerSlot,
  primaryAction,
}) => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${gray.gray6}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          height: 60,
          position: "relative",
        }}
      >
        <Flex align="center" gap="10px">
          <Icon size={20} strokeWidth={2} color={gray.gray8} />
          <Flex align="center" gap="10px" style={{ userSelect: "none" }}>
            <Text size="5" weight="medium">
              {sectionTitle}
            </Text>
            {pageTitle && (
              <>
                <Text size="5" weight="medium" style={{ color: gray.gray7 }}>
                  /
                </Text>
                <Text
                  size="5"
                  weight="medium"
                  truncate
                  style={{ color: violet.violet9, maxWidth: 640 }}
                >
                  {pageTitle}
                </Text>
              </>
            )}
            {suffixSlot && suffixSlot}
          </Flex>
        </Flex>

        {/* Center slot with absolute positioning */}
        {centerSlot && (
          <Flex
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1,
            }}
          >
            {centerSlot}
          </Flex>
        )}

        {primaryAction && primaryAction}
      </Flex>
    </Flex>
  );
};

export default Toolbar;
