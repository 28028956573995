import { formatDistanceToNowStrict, format } from "date-fns";

export const toFullDate = (dateString) => {
  if (!dateString) return "";
  return format(new Date(dateString), "MMM d, yyyy");
};

export const prettifyDate = (dateString, includeTime = false) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const now = new Date();
  const yesterday = new Date(now.setDate(now.getDate() - 1));

  const formattedDate = (() => {
    if (date.toDateString() === new Date().toDateString()) return "Today";
    if (date.toDateString() === yesterday.toDateString()) return "Yesterday";

    return format(
      date,
      date.getFullYear() === new Date().getFullYear() ? "MMM d" : "MMM d, yyyy"
    );
  })();

  return includeTime
    ? `${formattedDate}, ${date.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}`
    : formattedDate;
};

export const toTimeAgo = (dateString, short = false) => {
  if (!dateString) return "";

  try {
    const timeAgo = formatDistanceToNowStrict(new Date(dateString), {
      addSuffix: true,
      width: short ? "short" : "long",
    });

    if (timeAgo.match(/^0\s*s(econds)?\s+ago$/)) return "Just now";
    if (!short) return timeAgo;

    const abbreviations = {
      minute: "m",
      minutes: "m",
      second: "s",
      seconds: "s",
      hour: "h",
      hours: "h",
      day: "d",
      days: "d",
      month: "mo",
      months: "mo",
      year: "y",
      years: "y",
    };

    return timeAgo.replace(
      /(\d+)\s+(minute|second|hour|day|month|year)s?/g,
      (_, num, unit) => `${num}${abbreviations[unit]}`
    );
  } catch {
    return "";
  }
};

export const abbrevNum = (num) => {
  const formatNumber = (n, suffix) => {
    const formatted = n.toFixed(1);
    return formatted.endsWith(".0")
      ? formatted.slice(0, -2) + suffix
      : formatted + suffix;
  };

  if (num >= 1000000) return formatNumber(num / 1000000, "M");
  if (num >= 1000) return formatNumber(num / 1000, "k");
  return formatNumber(num, "");
};

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const pluralize = (word, count, plural = `${word}s`) => {
  return count === 1 ? word : plural;
};

export const toPercent = (value, decimalPlaces = 0) => {
  if (value === 0) return "0%";
  return `${(value * 100).toFixed(decimalPlaces)}%`;
};

export const hoursToFrequency = (hours) => {
  if (hours === 24) return "Daily";
  if (hours === 168) return "Weekly";
  if (hours % 24 === 0) return `${hours / 24} days`;
  return `${hours} hours`;
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};
