import React, { useEffect, useState, useMemo } from "react";
import {
  Text,
  Flex,
  ScrollArea,
  Link,
  Tooltip,
  Button,
  DropdownMenu,
  Separator,
  Badge,
  Select,
  TextArea,
  SegmentedControl,
  RadioCards,
  Popover,
  TextField,
  Spinner,
  HoverCard,
  Skeleton,
} from "@radix-ui/themes";
import {
  amber,
  blue,
  gray,
  grayA,
  green,
  indigo,
  pink,
  red,
  violet,
} from "@radix-ui/colors";
import {
  Activity,
  BadgeAlert,
  ChartColumnBig,
  ChevronDown,
  CircleCheckBig,
  CircleDotDashed,
  ClipboardCheck,
  ClipboardCopy,
  Database,
  ExternalLink,
  ExternalLinkIcon,
  FolderOpen,
  Key,
  KeyRound,
  Mail,
  MessageSquarePlus,
  PanelRightClose,
  PanelRightOpen,
  Radar,
  Send,
  Siren,
  SquareArrowOutUpRight,
  Tag,
  TextSelect,
  TriangleAlert,
} from "lucide-react";
import { toast } from "react-hot-toast";

import { useAccount } from "./context/AccountContext";
import Toolbar from "./Toolbar";
import Search from "./Search";
import AccountFilters, {
  FILTER_TYPES,
  useAccountFiltering,
} from "./AccountFilters";
import EmptyState, { Loading, NoSearchResults } from "./EmptyState";
import AccountSidebar from "./AccountSidebar";
import { prettifyDate, toTimeAgo } from "./utils";
import "./IssueList.css";

const PageContainer = ({
  accounts,
  filters,
  onFilter,
  searchTerm,
  onSearch,
  activeView,
  onActiveViewChange,
  children,
}) => {
  const hasContactedAccounts = useMemo(() => {
    return accounts.some((account) => account.flag.messages?.length > 0);
  }, [accounts]);

  return (
    <Flex className="issue-list" direction="column">
      <Toolbar
        icon={BadgeAlert}
        sectionTitle={"Accounts"}
        pageTitle={"Issues"}
        suffixSlot={
          <AccountFilters
            accounts={accounts}
            filters={filters}
            onFilter={onFilter}
          />
        }
        centerSlot={
          <SegmentedControl.Root
            value={activeView}
            onValueChange={onActiveViewChange}
            size="2"
            radius="large"
            style={{
              borderRadius: 8,
            }}
          >
            <SegmentedControl.Item value="not_contacted">
              <Flex align="center" gap="2">
                <CircleDotDashed size={13} color={gray.gray8} strokeWidth={2} />
                <Text size="1" style={{ fontSize: 13 }}>
                  Not contacted
                </Text>
              </Flex>
            </SegmentedControl.Item>
            <SegmentedControl.Item
              value="contacted"
              onClick={(e) => {
                if (!hasContactedAccounts) {
                  e.preventDefault();
                }
              }}
              style={{
                cursor: hasContactedAccounts ? "pointer" : "not-allowed",
              }}
            >
              {hasContactedAccounts ? (
                <Flex align="center" gap="2">
                  <Send size={12} color={gray.gray8} />
                  <Text
                    size="1"
                    mr="1"
                    style={{
                      fontSize: 13,
                    }}
                  >
                    Contacted
                  </Text>
                </Flex>
              ) : (
                <Tooltip
                  content="You haven't contacted any users yet"
                  side="right"
                >
                  <Flex align="center" gap="2">
                    <Send size={12} color={gray.gray6} />
                    <Text
                      size="1"
                      mr="1"
                      style={{ fontSize: 13, color: gray.gray8 }}
                    >
                      Contacted
                    </Text>
                  </Flex>
                </Tooltip>
              )}
            </SegmentedControl.Item>
          </SegmentedControl.Root>
        }
        primaryAction={
          <Search
            value={searchTerm}
            onChange={onSearch}
            shortcutEnabled={true}
          />
        }
      />

      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <Flex
          direction="column"
          gap="5"
          style={{
            minWidth: 860,
            margin: "0 auto",
            width: "100%",
            height: "100%",
          }}
        >
          <Flex direction="column" gap="4" style={{ height: "100%" }}>
            {children}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

const SupportingRecord = ({
  type,
  id,
  description,
  external_created_at: created_at,
  created_by,
}) => {
  return (
    <Flex direction="column" gap="1" px="3" py="0">
      <Flex align="center" gap="6px">
        {created_by?.name && (
          <Text size="1" style={{ color: gray.gray10, fontWeight: 600 }}>
            {created_by.name}
          </Text>
        )}
        <Text size="1" style={{ color: gray.gray9, opacity: 0.8 }}>
          {prettifyDate(created_at, true)}
        </Text>
      </Flex>
      <Text size="2" style={{ color: gray.gray12 }}>
        {description}
      </Text>
    </Flex>
  );
};

const ReferenceCard = ({
  type,
  url,
  title,
  body,
  supportingRecords,
  createdAt,
  dataSource,
}) => {
  const { icon: Icon, title: referenceTitle } = flagConfig[type].reference;

  return (
    <Flex
      className="reference-card"
      direction="column"
      style={{
        border: `1px solid ${gray.gray4}`,
        borderRadius: 8,
        textDecoration: "none",
        overflow: "hidden",
        width: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Link href={url} underline="none" target="_blank" rel="noreferrer">
        <Flex justify="between" align="center" py="2" pl="3" pr="4" gap="5">
          <Flex direction="column">
            <Flex align="center" gap="1">
              <Icon size={12} color={blue.blue8} />
              <Text size="1" style={{ color: gray.gray11 }}>
                {referenceTitle}
              </Text>
              {createdAt && (
                <Text size="1" ml="2px" style={{ color: gray.gray8 }}>
                  {toTimeAgo(createdAt, true)}
                </Text>
              )}
            </Flex>
            <Text size="2" weight="medium" style={{ color: gray.gray12 }}>
              {title}
            </Text>
          </Flex>

          <Flex align="center" gap="4">
            {dataSource && (
              <Tooltip content={dataSource.description}>
                <Badge
                  color="gray"
                  variant="outline"
                  size="1"
                  radius="large"
                  style={{
                    background: "#fff",
                    opacity: 0.7,
                    fontSize: 11,
                    padding: "2px 6px",
                    cursor: "default",
                  }}
                >
                  <Database
                    size={10}
                    color={gray.gray10}
                    style={{ marginRight: -2 }}
                  />
                  {dataSource.title}
                </Badge>
              </Tooltip>
            )}

            <SquareArrowOutUpRight
              className="reference-link"
              size={14}
              color={gray.gray8}
            />
          </Flex>
        </Flex>
      </Link>

      {body && (
        <Flex
          px="3"
          py="3"
          direction="column"
          style={{
            background: gray.gray1,
            borderTop: `1px solid ${gray.gray4}`,
            cursor: "auto",
          }}
        >
          {supportingRecords.length > 0 ? (
            <Flex direction="column" gap="4" py="1">
              {supportingRecords
                .sort((a, b) => {
                  const dateA = a.external_created_at
                    ? new Date(a.external_created_at)
                    : new Date(0);
                  const dateB = b.external_created_at
                    ? new Date(b.external_created_at)
                    : new Date(0);
                  return dateB - dateA;
                })
                .map((record, i) => (
                  <Flex key={record.id} direction="column" gap="4">
                    <SupportingRecord {...record} />
                    {i < supportingRecords.length - 1 && (
                      <Separator
                        orientation="horizontal"
                        size="4"
                        style={{
                          background: "none",
                          borderBottom: `1px dashed ${gray.gray6}`,
                        }}
                      />
                    )}
                  </Flex>
                ))}
            </Flex>
          ) : (
            <Text
              size="2"
              mx="3"
              my="1"
              style={{ color: gray.gray11, whiteSpace: "pre-wrap" }}
            >
              {body}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const flagConfig = {
  monitor: {
    icon: TextSelect,
    reference: {
      icon: Radar,
      title: "Monitor result",
      getUrl: (monitorId, accountId) => `/monitors/${monitorId}#${accountId}`,
    },
  },
  rule: {
    icon: ChartColumnBig,
    rationale:
      "Billing/invoice usage is below your company's defined threshold and is therefore considered at risk.",
  },
};

const EmailClientButton = ({
  icon: Icon,
  label,
  color = gray.gray11,
  externalLink = true,
  disabled,
  onClick,
}) => (
  <Button
    variant="ghost"
    color="gray"
    size="2"
    radius="large"
    style={{
      padding: "8px 12px",
      color,
    }}
    onClick={onClick}
    disabled={disabled}
  >
    <Flex align="center" justify="between" style={{ width: "100%" }}>
      <Flex align="center" gap="2">
        <Icon size={15} strokeWidth={1.5} />
        <Text size="2" weight="medium">
          {label}
        </Text>
      </Flex>
      {externalLink && (
        <ExternalLink size={14} strokeWidth={1.5} color={grayA.grayA8} />
      )}
    </Flex>
  </Button>
);

const SendEmailPopover = ({ recipientEmail, subject, body }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Popover.Root>
      <Popover.Trigger className="send-email-trigger" asChild>
        <Button size="2" radius="large" style={{ height: 28 }}>
          Send
          <ChevronDown size={12} style={{ marginLeft: 0 }} />
        </Button>
      </Popover.Trigger>

      <Popover.Content
        align="end"
        radius="large"
        size="1"
        style={{
          width: 248,
        }}
      >
        <Flex direction="column" gap="3">
          <Flex
            direction="column"
            gap="2px"
            p="3"
            style={{
              backgroundColor: gray.gray2,
              borderRadius: 8,
            }}
          >
            <Text size="2" weight="medium">
              Send email
            </Text>
            <Text size="1" style={{ color: grayA.grayA11 }}>
              Choose your preferred email client
            </Text>
          </Flex>

          <Flex direction="column" gap="2" px="1">
            <EmailClientButton
              icon={Mail}
              label="Open in Gmail"
              color={red.red11}
              onClick={() =>
                window.open(
                  `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
                )
              }
            />
            <EmailClientButton
              icon={Mail}
              label="Open in Outlook"
              color={blue.blue11}
              onClick={() =>
                window.open(
                  `ms-outlook://compose?to=${recipientEmail}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
                )
              }
            />
            <EmailClientButton
              icon={Mail}
              label="Open in default"
              onClick={() =>
                window.open(
                  `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
                )
              }
            />

            {/* <Separator size="4" my="1" style={{ opacity: 0.3 }} /> */}

            <EmailClientButton
              icon={copied ? ClipboardCheck : ClipboardCopy}
              externalLink={false}
              label={copied ? "Copied!" : "Copy to clipboard"}
              color={copied ? "var(--green-11)" : gray.gray11}
              disabled={copied}
              onClick={() => {
                navigator.clipboard.writeText(body);
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              }}
            />

            {/* <Separator size="4" my="1" style={{ opacity: 0.3 }} />

            <Button
              size="2"
              radius="large"
              variant="soft"
              color="green"
              // onClick={handleMarkAsSent}
            >
              <CircleCheckBig size={12} />
              Mark as sent
            </Button> */}
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};

const UserInfoCard = ({ user }) => {
  if (!user) return null;

  const hasFreshFacts =
    user.custom_record_facts &&
    user.custom_record_facts.length > 0 &&
    Date.now() - new Date(user.custom_record_facts[0].created_at) <
      2 * 24 * 60 * 60 * 1000;

  return (
    <Flex direction="column" gap="3" style={{ width: 220 }}>
      <Flex direction="column">
        <Text size="3" weight="medium">
          {user.name}
        </Text>
        <Text
          size="2"
          truncate={true}
          style={{ color: gray.gray11, maxWidth: 220 }}
        >
          {user.email}
        </Text>
        {user.last_login && (
          <Tooltip content={prettifyDate(user.last_login, true)}>
            <Flex
              align="center"
              gap="6px"
              mt="2"
              style={{ cursor: "default", width: "fit-content" }}
            >
              <KeyRound size={12} strokeWidth={1.5} color={gray.gray9} />
              <Text size="1" style={{ color: gray.gray10 }}>
                Last login {toTimeAgo(user.last_login, true)}
              </Text>
            </Flex>
          </Tooltip>
        )}
      </Flex>

      {hasFreshFacts && (
        <Flex direction="column" gap="3">
          <Separator size="4" />
          <Flex align="center" gap="1">
            <Activity size={12} strokeWidth={1.5} color={gray.gray9} />
            <Text
              size="1"
              weight="medium"
              ml="2px"
              style={{ color: gray.gray11 }}
            >
              Weekly activity
            </Text>
            <Text size="1" style={{ color: gray.gray9 }}>
              (updated {toTimeAgo(user.custom_record_facts[0].created_at, true)}
              )
            </Text>
          </Flex>

          <Flex
            direction="column"
            gap="1"
            px="2"
            py="1"
            style={{
              background: gray.gray2,
              borderRadius: 6,
              border: `1px solid ${gray.gray3}`,
            }}
          >
            {user.custom_record_facts.map((fact, index) => (
              <>
                <Flex key={index} justify="between" align="center">
                  <Text size="1" weight="medium" style={{ color: gray.gray11 }}>
                    {fact.custom_record_definition.name}
                  </Text>
                  <Badge
                    size="1"
                    color="gray"
                    variant="soft"
                    style={{
                      fontSize: 11,
                    }}
                  >
                    {fact.value}
                  </Badge>
                </Flex>
                {index < user.custom_record_facts.length - 1 && (
                  <Separator
                    size="4"
                    style={{ opacity: 0.3, margin: "0 -2px" }}
                  />
                )}
              </>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const UserSelectItem = ({ recipient, ...props }) => (
  <HoverCard.Root openDelay={0} closeDelay={0}>
    <HoverCard.Trigger asChild>
      <Select.Item key={recipient.id} value={recipient.id} {...props}>
        <Flex align="center" gap="2">
          <Text size="2">{recipient.name}</Text>
        </Flex>
      </Select.Item>
    </HoverCard.Trigger>
    <HoverCard.Content side="right" sideOffset={4} align="start">
      <UserInfoCard user={recipient} />
    </HoverCard.Content>
  </HoverCard.Root>
);

const EXAMPLE_MESSAGE = {
  subject: "Example subject line that needs attention",
  body: `Hi Marc,

I noticed you've had some questions recently about how to classify different documents in the system. I saw you weren't sure about invoice vs. receipt classification, and there was also some uncertainty about the Lowe Residence job.

Would it be helpful if I put together a quick guide on how we typically handle different document types? Just want to make sure you're feeling confident about where things should go.

Happy to hop on a quick call too if you think that'd be more useful. Let me know what works best for you.

Feel free to reply with any questions or feedback.`,
};

const InAppMessageForm = ({ account, onCancel }) => {
  const {
    sendInAppMessage,
    getEndUsersForAccount,
    getDraftEmailForMonitorFlag,
  } = useAccount();
  const [deliveryMethod, setDeliveryMethod] = useState("email");
  const [recipients, setRecipients] = useState([]);
  const [suggestedRecipients, setSuggestedRecipients] = useState([]);
  const [otherRecipients, setOtherRecipients] = useState([]);
  const [recipientId, setRecipientId] = useState("");
  const [message, setMessage] = useState({
    subject: "",
    body: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const loadEndUsers = async () => {
      try {
        setIsLoading(true);
        const endUsers = await getEndUsersForAccount(account.id);
        endUsers.sort((a, b) => a.name?.localeCompare(b.name || ""));
        setRecipients(endUsers);

        // Get supporting records user IDs
        const supportingRecords =
          account.flag?.source?.monitor_evaluation?.supporting_records || [];
        const supportingRecordUserIds = supportingRecords
          .filter((record) => record.created_by?.id)
          .map((record) => record.created_by.id);

        // Split recipients into suggested and others and sort alphabetically
        const suggested = endUsers.filter((user) =>
          supportingRecordUserIds.includes(user.id)
        );

        const others = endUsers.filter(
          (user) => !supportingRecordUserIds.includes(user.id)
        );

        setSuggestedRecipients(suggested);
        setOtherRecipients(others);

        // Set default recipient (first suggested or first in list)
        if (suggested.length > 0) {
          setRecipientId(suggested[0].id);
        } else if (endUsers.length > 0) {
          setRecipientId(endUsers[0].id);
        }
      } catch (error) {
        console.error("Failed to load end users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadEndUsers();
  }, [account, getEndUsersForAccount]);

  useEffect(() => {
    const loadDraftEmail = async () => {
      if (!recipientId || !account.flag?.id) return;

      try {
        setIsLoading(true);
        const draft = await getDraftEmailForMonitorFlag(
          account.id,
          recipientId,
          account.flag.id
        );
        setMessage({
          subject: draft?.subject || "",
          body: draft?.body || "",
        });
      } catch (error) {
        console.error("Failed to load draft email:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadDraftEmail();
  }, [recipientId, account, getDraftEmailForMonitorFlag]);

  const handleSend = async (e) => {
    e.preventDefault();
    if (!recipientId) return;

    try {
      setIsSending(true);
      await sendInAppMessage(recipientId, message.body, deliveryMethod);
      onCancel();
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      setIsSending(false);
    }
  };

  const handleMessageSent = (recipientName) => {
    toast.success(
      <Flex className="status-toast" align="center" gap="3">
        <Text weight="medium">{`In-app message sent to ${recipientName}`}</Text>
      </Flex>,
      {
        position: "bottom-center",
        style: {
          background: "rgba(0, 0, 0, 0.6)",
          backdropFilter: "blur(6px)",
          color: "#fff",
          borderRadius: "999px",
          paddingLeft: "20px",
          paddingRight: "12px",
          maxWidth: "unset",
        },
      }
    );
  };

  const selectedRecipient = recipients.find(
    (recipient) => recipient.id === recipientId
  );

  return (
    <Flex
      className="in-app-message-form"
      justify="between"
      align="center"
      mt="2"
      style={{
        background: gray.gray2,
        borderTop: `1px solid ${gray.gray5}`,
        margin: "0 -24px -20px",
        borderRadius: "0 0 15px 15px",
        cursor: "default",
        overflow: "hidden",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex direction="column" width="100%">
        <Flex
          align="center"
          justify="between"
          pl="4"
          pr="3"
          py="3"
          style={{ borderBottom: `1px solid ${gray.gray5}` }}
        >
          <Flex align="center" gap="10px">
            <MessageSquarePlus size={24} strokeWidth={1.5} color={gray.gray8} />
            <Text size="4" weight="medium" style={{ color: gray.gray12 }}>
              New message to impacted user
            </Text>
          </Flex>

          <Flex align="center" gap="2">
            <Button
              size="2"
              variant="outline"
              color="gray"
              radius="large"
              style={{
                boxShadow: "none",
                border: `1px solid ${grayA.grayA5}`,
                height: 28,
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            {deliveryMethod === "intercom" ? (
              <Button
                size="2"
                color="violet"
                radius="large"
                style={{
                  height: 28,
                }}
                onClick={handleSend}
                disabled={!message.body?.trim() || isSending || isLoading}
              >
                {isSending ? "Sending..." : "Send"}
              </Button>
            ) : (
              <SendEmailPopover
                recipientEmail={selectedRecipient?.email}
                subject={message.subject}
                body={message.body}
              />
            )}
          </Flex>
        </Flex>

        <Flex>
          <Flex
            direction="column"
            gap="4"
            px="4"
            pt="3"
            pb="5"
            width="100%"
            maxWidth="240px"
          >
            {/* Send to */}
            <Flex direction="column" gap="1">
              <Text size="1" weight="medium" style={{ color: gray.gray10 }}>
                Send to
              </Text>
              <Select.Root value={recipientId} onValueChange={setRecipientId}>
                {/* <HoverCard.Root openDelay={300} closeDelay={0}>
                  <HoverCard.Trigger asChild> */}
                <Select.Trigger
                  asChild
                  className="send-to-trigger"
                  style={{
                    minHeight: deliveryMethod === "email" ? "56px" : "40px",
                  }}
                  placeholder={
                    isLoading ? (
                      <Flex align="center" gap="2">
                        <Spinner size="1" />
                        <Text size="2">Loading...</Text>
                      </Flex>
                    ) : (
                      "Select a recipient..."
                    )
                  }
                >
                  <Flex direction="column">
                    <Text
                      size="2"
                      weight="medium"
                      truncate={true}
                      style={{ maxWidth: 180 }}
                    >
                      {selectedRecipient
                        ? selectedRecipient.name
                        : "Select a recipient..."}
                    </Text>
                    {deliveryMethod === "email" && selectedRecipient && (
                      <Text
                        size="1"
                        truncate={true}
                        style={{ color: gray.gray10, maxWidth: 180 }}
                      >
                        {selectedRecipient.email}
                      </Text>
                    )}
                  </Flex>
                </Select.Trigger>
                {/* </HoverCard.Trigger>
                  <HoverCard.Content align="start" sideOffset={10}>
                    <UserInfoCard user={selectedRecipient} />
                  </HoverCard.Content>
                </HoverCard.Root> */}
                <Select.Content position="popper">
                  {suggestedRecipients.length > 0 && (
                    <>
                      <Select.Group>
                        <Select.Label>
                          <Text
                            size="1"
                            weight="medium"
                            style={{ color: gray.gray10 }}
                          >
                            Suggested
                          </Text>
                        </Select.Label>
                        {suggestedRecipients.map((recipient) => (
                          <UserSelectItem
                            key={recipient.id}
                            recipient={recipient}
                          />
                        ))}
                      </Select.Group>
                    </>
                  )}
                  {otherRecipients.length > 0 && (
                    <>
                      <Select.Separator />
                      <Select.Group>
                        {/* <Select.Label>All recipients</Select.Label> */}
                        {otherRecipients.map((recipient) => (
                          <UserSelectItem
                            key={recipient.id}
                            recipient={recipient}
                          />
                        ))}
                      </Select.Group>
                    </>
                  )}
                </Select.Content>
              </Select.Root>
            </Flex>

            {/* Delivery method */}
            <Flex direction="column" gap="1">
              <Text size="1" weight="medium" style={{ color: gray.gray10 }}>
                Delivery method
              </Text>
              <RadioCards.Root
                value={deliveryMethod}
                onValueChange={setDeliveryMethod}
                size="1"
                gap="2"
              >
                <RadioCards.Item value="email">
                  <Flex direction="column" width="100%">
                    <Text size="2" weight="medium">
                      Email
                    </Text>
                    <Text size="1" style={{ color: gray.gray10 }}>
                      Delivered directly to inbox
                    </Text>
                  </Flex>
                </RadioCards.Item>

                <RadioCards.Item value="intercom" disabled>
                  <Flex direction="column" width="100%">
                    <Text size="2" weight="medium">
                      Intercom chat
                    </Text>
                    <Text size="1" style={{ color: gray.gray10 }}>
                      Sent as a new in-app message
                    </Text>
                  </Flex>
                </RadioCards.Item>
              </RadioCards.Root>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            width="100%"
            style={{
              position: "relative",
              background: "white",
              borderLeft: `1px solid ${gray.gray5}`,
            }}
          >
            <Flex direction="column">
              <Text
                size="1"
                weight="medium"
                style={{ color: gray.gray10, margin: "8px 12px -4px" }}
              >
                Subject
              </Text>
              {isLoading ? (
                <Flex
                  direction="column"
                  align="start"
                  justify="center"
                  gap="1"
                  py="2"
                  px="3"
                  style={{
                    width: "100%",
                    height: 36,
                    // background: "#fff",
                    borderBottom: "1px solid var(--gray-a5)",
                    // borderRadius: 8,
                  }}
                >
                  <Text size="2">
                    <Skeleton>{EXAMPLE_MESSAGE.subject}</Skeleton>
                  </Text>
                </Flex>
              ) : (
                <TextField.Root
                  placeholder="Subject line..."
                  size="2"
                  variant="surface"
                  color="gray"
                  radius="large"
                  value={message.subject}
                  onChange={(e) =>
                    setMessage({ ...message, subject: e.target.value })
                  }
                  disabled={isLoading || isSending}
                  style={{
                    boxShadow: "none",
                    borderBottom: "1px solid var(--gray-a5)",
                    borderRadius: 0,
                    minHeight: 36,
                  }}
                />
              )}
            </Flex>

            <Flex direction="column" gap="1" style={{ marginTop: -1 }}>
              {/* <Text
                size="1"
                weight="medium"
                ml="2"
                style={{ color: gray.gray10 }}
              >
                {deliveryMethod === "email" ? "Body" : "Message content"}
              </Text> */}
              {isLoading ? (
                <Flex
                  direction="column"
                  gap="1"
                  p="3"
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: 320,
                    // background: "#fff",
                    // border: "1px solid var(--gray-a5)",
                    borderRadius: 0,
                  }}
                >
                  <Text size="2" style={{ whiteSpace: "pre-wrap" }}>
                    <Skeleton>{EXAMPLE_MESSAGE.body}</Skeleton>
                  </Text>
                </Flex>
              ) : (
                <TextArea
                  placeholder="Write your message here..."
                  size="2"
                  variant="surface"
                  color="gray"
                  radius="none"
                  resize="none"
                  disabled={isLoading || isSending}
                  value={message.body}
                  onChange={(e) =>
                    setMessage({ ...message, body: e.target.value })
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: 320,
                    boxShadow: "none",
                  }}
                />
              )}
            </Flex>

            {isLoading && (
              <Flex
                align="center"
                justify="center"
                direction="column"
                gap="2"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backdropFilter: "blur(1px)",
                  borderRadius: 8,
                }}
              >
                <Spinner size="3" />
                <Text size="1" weight="medium" style={{ color: gray.gray11 }}>
                  Generating...
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const MessageHistory = ({ messages }) => {
  if (!messages || messages.length === 0) return null;

  // Sort messages by sent_at time, most recent first
  const sortedMessages = [...messages].sort(
    (a, b) => new Date(b.sent_at) - new Date(a.sent_at)
  );

  const latestMessage = sortedMessages[0];
  const messageCount = messages.length;
  const previewText =
    latestMessage.message.length > 200
      ? `${latestMessage.message.substring(0, 200)}...`
      : latestMessage.message;

  const tooltipContent = (
    <Flex direction="column" gap="2" p="1" style={{ maxWidth: 280 }}>
      <Flex direction="column" gap="1">
        <Flex justify="between" align="center">
          <Flex align="center" gap="1">
            <Text size="1" style={{ color: gray.gray5 }}>
              To:
            </Text>
            <Text size="1" weight="medium" style={{ color: gray.gray5 }}>
              {latestMessage.recipient?.name || "Unknown recipient"}
            </Text>
          </Flex>
          <Text size="1" weight="medium" style={{ color: gray.gray10 }}>
            {toTimeAgo(latestMessage.sent_at, true)}
          </Text>
        </Flex>
      </Flex>

      <Flex
        direction="column"
        px="2"
        py="1"
        mb="1"
        style={{
          background: "rgba(255,255,255, 0.05)",
          borderRadius: 6,
          border: `1px solid ${grayA.grayA3}`,
        }}
      >
        <Text
          size="1"
          style={{
            color: gray.gray5,
            lineHeight: 1.5,
            whiteSpace: "pre-wrap",
          }}
        >
          {previewText}
        </Text>
      </Flex>

      {messageCount > 1 && (
        <Text size="1" style={{ color: gray.gray10 }}>
          {messageCount} total messages sent
        </Text>
      )}
    </Flex>
  );

  return (
    <Flex
      className="message-history"
      py="1"
      px="2"
      justify="between"
      align="center"
      style={{
        background: indigo.indigo1,
        color: indigo.indigo10,
        cursor: "default",
        border: `1px solid ${indigo.indigo5}`,
        borderRadius: 6,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <Tooltip content={tooltipContent} sideOffset={5}> */}
      <Flex align="center" gap="2">
        <Send size={12} />

        <Text size="1" weight="medium">
          Sent in-app message
        </Text>
        <Text size="1" style={{ opacity: 0.5 }}>
          {toTimeAgo(latestMessage.sent_at, true)}
          {messageCount > 1 && ` +${messageCount - 1} more`}
        </Text>
      </Flex>
      {/* </Tooltip> */}
      <Flex align="center" gap="2" ml="2">
        <ExternalLinkIcon size={12} />
      </Flex>
    </Flex>
  );
};

const IssueCard = ({ account, onToggleSidebar, isActive }) => {
  const [showMessageForm, setShowMessageForm] = useState(false);
  const flag = account.flag;
  const config = flagConfig[flag.type] || {};
  const FlagIcon = config.icon || Siren;

  // Get message count for the indicator
  const messageCount = flag.messages?.length || 0;

  return (
    <Flex
      className="issue-card"
      onClick={onToggleSidebar}
      direction="column"
      py="20px"
      p="5"
      gap="4"
    >
      <Flex justify="between" align="center">
        <Flex align="center" gap="2">
          <Flex
            align="center"
            justify="center"
            style={{
              width: 20,
              height: 20,
              borderRadius: 6,
              background: amber.amber3,
            }}
          >
            <TriangleAlert
              size={14}
              color={amber.amber11}
              style={{ opacity: 0.7 }}
            />
          </Flex>
          <Text size="2" weight="medium" style={{ color: gray.gray10 }}>
            Issue
          </Text>
          <Tooltip content={prettifyDate(account.flag.flagged_at, true)}>
            <Text
              size="2"
              ml="1"
              style={{ color: gray.gray8, cursor: "default" }}
            >
              First detected {toTimeAgo(account.flag.flagged_at, true)}
            </Text>
          </Tooltip>
        </Flex>

        <Flex align="center" gap="20px">
          {messageCount > 0 && <MessageHistory messages={flag.messages} />}

          <Button
            className="sidebar-toggle"
            size="1"
            variant={isActive ? "soft" : "ghost"}
            color={isActive ? "violet" : "gray"}
            radius="large"
            style={{
              margin: isActive ? "-4px -8px" : "",
              padding: "4px",
              outline: "none",
              boxShadow: "none",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onToggleSidebar();
            }}
            data-state={isActive ? "active" : "inactive"}
          >
            <Tooltip
              content={
                isActive ? (
                  "Close sidebar"
                ) : (
                  <Flex direction="column">
                    <Text size="1" weight="medium">
                      View account details
                    </Text>
                    <Text size="1" style={{ color: gray.gray9 }}>
                      Opens in sidebar
                    </Text>
                  </Flex>
                )
              }
              sideOffset={10}
            >
              {isActive ? (
                <PanelRightClose size={18} color={violet.violet11} />
              ) : (
                <PanelRightOpen size={18} color={gray.gray8} />
              )}
            </Tooltip>
          </Button>
        </Flex>
      </Flex>

      <Text
        size="5"
        style={{ color: gray.gray12, fontWeight: 500, marginTop: -2 }}
      >
        {account.name}
      </Text>

      <Flex direction="column" gap="2" mb="2">
        <Flex align="center" gap="6px">
          <FlagIcon size={16} color={amber.amber8} />
          <Text
            size="2"
            weight="medium"
            style={{ color: amber.amber11, fontSize: 15 }}
          >
            {flag.title}
          </Text>
        </Flex>
        <Text size="2" style={{ color: gray.gray11, whiteSpace: "pre-wrap" }}>
          {flag.rationale || config.rationale}
        </Text>

        {flag.type === "monitor" && (
          <Flex direction="column" gap="6px" mt="2">
            <Text size="1" weight="medium" style={{ color: gray.gray10 }}>
              Source:
            </Text>
            <ReferenceCard
              type={flag.type}
              url={config.reference.getUrl(flag.source?.monitor.id, account.id)}
              title={flag.source?.monitor.label}
              body={flag.source?.monitor_evaluation.evidence}
              dataSource={{
                title: flag.source?.monitor.custom_record_definition.name,
                description:
                  flag.source?.monitor.custom_record_definition.description,
              }}
              supportingRecords={
                flag.source?.monitor_evaluation.supporting_records
              }
              createdAt={account.flag.flagged_at}
            />
          </Flex>
        )}
      </Flex>

      {showMessageForm ? (
        <InAppMessageForm
          account={account}
          onCancel={() => setShowMessageForm(false)}
        />
      ) : (
        <Flex
          className="card-footer"
          justify="between"
          align="center"
          mt="2"
          px="5"
          py="14px"
          style={{
            background: gray.gray2,
            borderTop: `1px solid ${gray.gray4}`,
            margin: "0 -24px -20px",
            borderRadius: "0 0 15px 15px",
            cursor: "default",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Flex align="center" gap="4">
            <Button
              size="2"
              variant="outline"
              radius="large"
              style={{
                boxShadow: "none",
                border: `1px solid ${violet.violet4}`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowMessageForm(true);
              }}
            >
              <MessageSquarePlus
                size={16}
                strokeWidth={2}
                color={violet.violet8}
              />{" "}
              Send message
            </Button>

            {/* Message count indicator */}
            {/* {messageCount > 0 && (
              <Tooltip
                content={`${messageCount} message${messageCount > 1 ? "s" : ""} sent`}
              >
                <Flex align="center" gap="1">
                  <MessageSquare size={14} color={gray.gray8} />
                  <Text size="2" weight="medium" style={{ color: gray.gray9 }}>
                    {messageCount}
                  </Text>
                </Flex>
              </Tooltip>
            )} */}
          </Flex>

          {account.labels.length > 0 && (
            <Flex align="center" gap="5px">
              <Tag size={14} color={gray.gray8} />
              <Text size="2" style={{ color: gray.gray9, opacity: 0.8 }}>
                {account.labels.map((label) => label.name).join(", ")}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const NoIssues = () => (
  <EmptyState
    title="No accounts with issues"
    description="When you have accounts that need your attention, they'll appear here."
    icon={FolderOpen}
    maxWidth={264}
  />
);

const IssueList = () => {
  const { getAccountsNeedingReview } = useAccount();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    [FILTER_TYPES.LABEL]: "all",
  });
  const [activeView, setActiveView] = useState("not_contacted");

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const issueAccounts = await getAccountsNeedingReview();
        setAccounts(issueAccounts);
      } catch (error) {
        console.error("Error fetching accounts with issues:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  // Close sidebar when escape key is pressed
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" && selectedAccount) {
        setSelectedAccount(null);
      }
    };

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [selectedAccount]);

  const handleToggleSidebar = (account) => {
    setSelectedAccount((prevSelected) =>
      prevSelected?.id === account.id ? null : account
    );
  };

  // Handle filter changes
  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({ ...prev, [type]: value }));
  };

  // Filter accounts based on view and other filters
  const { results: filteredAccounts } = useAccountFiltering(
    accounts.filter((account) => {
      const hasMessages = account.flag.messages?.length > 0;
      return activeView === "contacted" ? hasMessages : !hasMessages;
    }),
    filters,
    searchTerm
  );

  filteredAccounts.sort(
    (a, b) => new Date(b.flag.flagged_at) - new Date(a.flag.flagged_at)
  );

  const renderContent = () => {
    if (loading) return <Loading />;
    if (!accounts || accounts.length === 0) return <NoIssues />;
    if (filteredAccounts.length === 0) return <NoSearchResults />;

    return (
      <Flex
        style={{
          width: "100%",
          height: "calc(100vh - 80px)",
          background: gray.gray1,
        }}
      >
        <ScrollArea scrollbars="vertical" type="scroll">
          <Flex
            direction="column"
            align="center"
            style={{
              minWidth: 860,
              margin: "0 auto",
              width: "100%",
              height: "100%",
            }}
          >
            <Flex
              direction="column"
              py="5"
              gap="5"
              style={{
                width: "100%",
                maxWidth: 720,
                transition: "width 0.3s ease",
              }}
            >
              {filteredAccounts.map((account) => (
                <IssueCard
                  key={account.id}
                  account={account}
                  onToggleSidebar={() => handleToggleSidebar(account)}
                  isActive={selectedAccount?.id === account.id}
                />
              ))}
            </Flex>
          </Flex>
        </ScrollArea>

        {selectedAccount && (
          <AccountSidebar
            account={selectedAccount}
            onNoteAdded={(newNote) => {
              setSelectedAccount((prevAccount) => ({
                ...prevAccount,
                notes: [...prevAccount.notes, newNote],
              }));
              setAccounts((prevAccounts) =>
                prevAccounts.map((account) =>
                  account.id === selectedAccount.id
                    ? { ...account, notes: [...account.notes, newNote] }
                    : account
                )
              );
            }}
            onClose={() => setSelectedAccount(null)}
          />
        )}
      </Flex>
    );
  };

  return (
    <PageContainer
      accounts={accounts}
      filters={filters}
      onFilter={handleFilterChange}
      searchTerm={searchTerm}
      onSearch={setSearchTerm}
      activeView={activeView}
      onActiveViewChange={setActiveView}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default IssueList;
