import React, { useEffect, useState, useMemo } from "react";
import { Text, Flex, ScrollArea, Tooltip, Badge } from "@radix-ui/themes";
import { gray, violet } from "@radix-ui/colors";
import {
  Building2,
  Calendar,
  Flag,
  AlertTriangle,
  FolderInput,
  Folder,
  FolderOpen,
} from "lucide-react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { useAccount } from "./context/AccountContext";
import { useAuth } from "./context/AuthContext";
import Toolbar from "./Toolbar";
import Search, { HighlightedText } from "./Search";
import AccountFilters, {
  FILTER_TYPES,
  useAccountFiltering,
} from "./AccountFilters";
import AccountSidebar from "./AccountSidebar";
import DataGrid from "./DataGrid";
import MonitorBadge from "./MonitorBadge";
import EmptyState, {
  Loading,
  NoSearchResults,
  ProcessingAccounts,
} from "./EmptyState";
import { segment } from "./Analytics";
import { toTimeAgo, prettifyDate, pluralize } from "./utils";
import "./AccountList.css";

export const Touchpoint = ({ touchpoint }) => {
  if (!touchpoint) return null;

  return (
    <Flex direction="column" gap="1">
      <Tooltip content={prettifyDate(touchpoint.scheduled_for, true)}>
        <Text size="2" style={{ width: "fit-content" }}>
          {toTimeAgo(new Date(touchpoint.scheduled_for))}
        </Text>
      </Tooltip>
      <Flex direction="column" gap="2px">
        {touchpoint.type === "meeting" && (
          <Flex
            align="top"
            gap="1"
            style={{ color: gray.gray9, width: "fit-content" }}
          >
            <Calendar size={10} style={{ flexShrink: 0, marginTop: 3 }} />
            <Text wrap="pretty" style={{ fontSize: 11 }}>
              {touchpoint.data.meeting_title || "Meeting"}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const NeedsAttentionBadge = ({ account }) => {
  const flagged_at =
    account.needs_attention?.flagged_at || account.flag?.flagged_at;

  return (
    <Flex>
      <Tooltip
        content={
          <Flex direction="column">
            <Text weight="medium">Flagged as "At risk"</Text>
            <Text size="1" style={{ opacity: 0.6 }}>
              {toTimeAgo(new Date(flagged_at))}
            </Text>
          </Flex>
        }
        sideOffset={12}
      >
        <AlertTriangle
          size={14}
          strokeWidth={2.5}
          style={{
            color: "var(--amber-11)",
          }}
        />
      </Tooltip>
    </Flex>
  );
};

const FlaggedBadge = ({ account }) => {
  return (
    <Flex>
      <Tooltip
        content={
          <Flex direction="column" gap="2px">
            <Flex align="center" gap="2">
              <Text weight="medium">
                Flagged as "{account.flag.source.title}"
              </Text>
              <Text size="1" style={{ opacity: 0.6 }}>
                {toTimeAgo(new Date(account.flag.flagged_at), true)}
              </Text>
            </Flex>

            <Text>{account.flag.source.description}</Text>
          </Flex>
        }
        sideOffset={12}
        style={{ maxWidth: 232 }}
      >
        <AlertTriangle
          size={14}
          strokeWidth={2.5}
          style={{
            color: "var(--amber-11)",
          }}
        />
      </Tooltip>
    </Flex>
  );
};

// Empty states
const NoSectionAccounts = () => (
  <EmptyState
    title="No accounts in this folder"
    description="Select accounts and click 'Move' to add them to this folder"
    icon={FolderOpen}
    maxWidth={230}
  />
);

const PageContainer = ({
  icon = Building2,
  sectionTitle = "Accounts",
  pageTitle,
  accounts,
  filters,
  onFilter,
  searchTerm,
  onSearch,
  children,
}) => (
  <Flex className="account-list-container" direction="column">
    <Toolbar
      icon={icon}
      sectionTitle={sectionTitle}
      pageTitle={pageTitle}
      suffixSlot={
        <AccountFilters
          accounts={accounts}
          filters={filters}
          onFilter={onFilter}
        />
      }
      primaryAction={
        <Search value={searchTerm} onChange={onSearch} shortcutEnabled={true} />
      }
    />

    <ScrollArea
      scrollbars="vertical"
      type="scroll"
      style={{ height: "calc(100vh - 80px)" }}
    >
      <Flex
        direction="column"
        gap="5"
        style={{
          minWidth: 860,
          margin: "0 auto",
          width: "100%",
          height: "100%",
        }}
      >
        <Flex direction="column" gap="4" style={{ height: "100%" }}>
          {children}
        </Flex>
      </Flex>
    </ScrollArea>
  </Flex>
);

const WORKING_LIST_ALL_SECTION_ID = "all";
const NEEDS_REVIEW_SECTION_ID = "needs-review";

const createBulkSnoozeHandler = (
  accounts,
  setAccounts,
  dismissNeedsAttentionRule,
  callback
) => {
  return async (selectedRows) => {
    const updates = selectedRows.map((account) =>
      dismissNeedsAttentionRule(account.flag.source.id)
    );

    await Promise.all(updates);

    // Update state locally
    setAccounts((prevAccounts) =>
      prevAccounts.filter((account) => !selectedRows.includes(account))
    );

    toast.success(
      <Flex className="status-toast" align="center" gap="3">
        <Text weight="medium">{`Snoozed ${selectedRows.length} ${pluralize(
          "account",
          selectedRows.length
        )}`}</Text>
      </Flex>,
      {
        position: "bottom-center",
        style: {
          background: "rgba(0, 0, 0, 0.6)",
          backdropFilter: "blur(6px)",
          color: "#fff",
          borderRadius: "999px",
          paddingLeft: "20px",
          paddingRight: "12px",
          maxWidth: "unset",
        },
      }
    );

    segment.track("needs_review_snooze_accounts", {
      duration_hours: 168,
      accounts: selectedRows.map((account) => ({
        id: account.id,
        name: account.name,
      })),
    });
  };
};

const createBulkMoveHandler = (
  accounts,
  setAccounts,
  updateAccountInWorkingList,
  sections,
  targetSectionId
) => {
  return async (selectedRows) => {
    // Don't allow moving back to needs review section
    if (targetSectionId === NEEDS_REVIEW_SECTION_ID) {
      return;
    }

    // Find the target section to determine position
    const targetSection = sections.find((s) => s.id === targetSectionId);
    if (!targetSection) return;

    // Process updates sequentially
    for (const [index, account] of selectedRows.entries()) {
      const position = (targetSection?.accounts.length || 0) + index;
      await updateAccountInWorkingList(account.id, targetSectionId, position);
    }

    // Update state locally
    setAccounts((prevAccounts) =>
      prevAccounts.map((account) => {
        const selected = selectedRows.find((row) => row.id === account.id);
        if (selected) {
          return {
            ...account,
            sectionId: targetSectionId,
            sectionName: targetSection.name,
            sectionPosition: targetSection?.accounts.length || 0,
          };
        }
        return account;
      })
    );

    toast.success(
      <Flex className="status-toast" align="center" gap="3">
        <Text weight="medium">{`Moved ${selectedRows.length} ${pluralize(
          "account",
          selectedRows.length
        )} to ${targetSection?.name || "section"}`}</Text>
      </Flex>,
      {
        position: "bottom-center",
        style: {
          background: "rgba(0, 0, 0, 0.6)",
          backdropFilter: "blur(6px)",
          color: "#fff",
          borderRadius: "999px",
          paddingLeft: "20px",
          paddingRight: "12px",
          maxWidth: "unset",
        },
      }
    );

    // Track event
    const sourceSection = sections.find(
      (s) => s.id === selectedRows[0].sectionId
    );
    segment.track("move_accounts_to_section", {
      source_section: {
        id: sourceSection.id,
        name: sourceSection.name,
      },
      target_section: {
        id: targetSectionId,
        name: targetSection.name,
      },
      accounts: selectedRows.map((account) => ({
        id: account.id,
        name: account.name,
      })),
    });
  };
};

const TablePage = ({
  accounts = [],
  setAccounts = null,
  isLoading = false,
  icon,
  sectionTitle,
  pageTitle,
  additionalColumnDefs = [],
  bulkActions = [],
  emptyState = null,
  configKey = null,
}) => {
  const [filters, setFilters] = useState({
    [FILTER_TYPES.LABEL]: "all",
  });
  const [searchTerm, setSearchTerm] = useState("");

  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({ ...prev, [type]: value }));
  };

  return (
    <PageContainer
      icon={icon}
      sectionTitle={sectionTitle}
      pageTitle={pageTitle}
      accounts={accounts}
      filters={filters}
      onFilter={handleFilterChange}
      searchTerm={searchTerm}
      onSearch={setSearchTerm}
    >
      <AccountTable
        accounts={accounts}
        setAccounts={setAccounts}
        isLoading={isLoading}
        additionalColumnDefs={additionalColumnDefs}
        bulkActions={bulkActions}
        filters={filters}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        emptyState={emptyState}
        configKey={configKey}
      />
    </PageContainer>
  );
};

const AllAccountsList = () => {
  const { getAccountsHomeData, getWorkingList, updateAccountInWorkingList } =
    useAccount();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const [accountsData, workingListData] = await Promise.all([
          getAccountsHomeData(),
          getWorkingList(),
        ]);

        const accountToSectionMap = workingListData.sections.reduce(
          (acc, section) => {
            section.accounts.forEach((account) => {
              acc[account.id] = section;
            });
            return acc;
          },
          {}
        );

        const transformedAccounts = accountsData.map((account) => ({
          ...account,
          sectionId: accountToSectionMap[account.id]?.id,
          sectionName: accountToSectionMap[account.id]?.name,
        }));

        setAccounts(transformedAccounts);
        setSections(workingListData.sections);
      } catch (error) {
        console.error("Error fetching all accounts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  // Define bulk actions for AllAccountsList
  const bulkActions = useMemo(() => {
    return sections.map((section) => ({
      label: `Move to: "${section.name}"`,
      icon: FolderInput,
      color: "blue",
      handler: createBulkMoveHandler(
        accounts,
        setAccounts,
        updateAccountInWorkingList,
        sections,
        section.id
      ),
    }));
  }, [sections, accounts, updateAccountInWorkingList]);

  const additionalColumnDefs = [
    // {
    //   headerName: "Folder",
    //   field: "sectionName",
    //   minWidth: 150,
    //   flex: 1,
    //   sortable: true,
    //   wrapText: false,
    //   cellStyle: {
    //     textOverflow: "clip",
    //   },
    //   cellRenderer: (params) => {
    //     if (!params.value) return null;
    //     return (
    //       <Badge
    //         size="1"
    //         variant="outline"
    //         color="gray"
    //         mt="10px"
    //         radius="full"
    //         style={{
    //           boxShadow: "none",
    //           border: "1px solid var(--gray-6)",
    //         }}
    //       >
    //         <Folder
    //           size={12}
    //           color={gray.gray9}
    //           strokeWidth={2}
    //           style={{ flexShrink: 0 }}
    //         />
    //         <Text size="1">{params.value}</Text>
    //       </Badge>
    //     );
    //   },
    // },
    {
      headerName: "Assigned to",
      field: "assigned_to",
      minWidth: 150,
      flex: 1,
      sortable: true,
      cellRenderer: ({ value: assignedToUser }) => {
        if (!assignedToUser) return null;
        return <Text size="2">{assignedToUser.first_name}</Text>;
      },
    },
  ];

  return (
    <TablePage
      accounts={accounts}
      setAccounts={setAccounts}
      isLoading={loading}
      icon={Building2}
      sectionTitle="Accounts"
      pageTitle="All"
      // bulkActions={bulkActions}
      additionalColumnDefs={additionalColumnDefs}
      emptyState={<ProcessingAccounts />}
      configKey="lateral_table_config__all_accounts"
    />
  );
};

const MyBookList = () => {
  const { user } = useAuth();
  const { getAccountsHomeData, getWorkingList, updateAccountInWorkingList } =
    useAccount();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const [accountsData, workingListData] = await Promise.all([
          getAccountsHomeData(user.id),
          getWorkingList(),
        ]);

        const accountToSectionMap = workingListData.sections.reduce(
          (acc, section) => {
            section.accounts.forEach((account) => {
              acc[account.id] = section;
            });
            return acc;
          },
          {}
        );

        const transformedAccounts = accountsData.map((account) => ({
          ...account,
          sectionId: accountToSectionMap[account.id]?.id,
          sectionName: accountToSectionMap[account.id]?.name,
        }));

        setAccounts(transformedAccounts);
        setSections(workingListData.sections);
      } catch (error) {
        console.error("Error fetching all accounts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  // Define bulk actions for MyBookList
  const bulkActions = useMemo(() => {
    return sections.map((section) => ({
      label: `Move to: "${section.name}"`,
      icon: FolderInput,
      color: "blue",
      handler: createBulkMoveHandler(
        accounts,
        setAccounts,
        updateAccountInWorkingList,
        sections,
        section.id
      ),
    }));
  }, [sections, accounts, updateAccountInWorkingList]);

  const additionalColumnDefs = [
    {
      headerName: "Folder",
      field: "sectionName",
      minWidth: 150,
      flex: 1,
      sortable: true,
      wrapText: false,
      cellStyle: {
        textOverflow: "clip",
      },
      cellRenderer: (params) => {
        if (!params.value) return null;
        return (
          <Badge
            size="1"
            variant="outline"
            color="gray"
            mt="10px"
            radius="full"
            style={{
              boxShadow: "none",
              border: "1px solid var(--gray-6)",
            }}
          >
            <Folder
              size={12}
              color={gray.gray9}
              strokeWidth={2}
              style={{ flexShrink: 0 }}
            />
            <Text size="1">{params.value}</Text>
          </Badge>
        );
      },
    },
  ];

  return (
    <TablePage
      accounts={accounts}
      setAccounts={setAccounts}
      isLoading={loading}
      icon={Building2}
      sectionTitle="Accounts"
      pageTitle="My book"
      // bulkActions={bulkActions}
      // additionalColumnDefs={additionalColumnDefs}
      emptyState={<ProcessingAccounts />}
      configKey="lateral_table_config__my_book"
    />
  );
};

const WorkingList = () => {
  const { user } = useAuth();
  const { getWorkingList, updateAccountInWorkingList, getAccountsHomeData } =
    useAccount();
  const { sectionId } = useParams();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState();
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const [accountsData, workingListData] = await Promise.all([
          getAccountsHomeData(user.id),
          getWorkingList(),
        ]);

        // Transform working list accounts
        const workingListAccounts = workingListData.sections.flatMap(
          (section) =>
            section.accounts
              .map((account) => {
                const accountData = accountsData.find(
                  (a) => a.id === account.id
                );
                // TODO: Remove once we've consolidated the API routes. For now, this catches the case where the account becomes unassigned but is still in their working list.
                if (!accountData) return null;

                return {
                  ...accountData,
                  sectionName: section.name,
                  sectionId: section.id,
                  sectionPosition: account.position,
                };
              })
              .filter(Boolean)
        );

        setAccounts(workingListAccounts);
        setSections(workingListData.sections);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  // Get the current section information
  const currentSection = useMemo(() => {
    if (sectionId === WORKING_LIST_ALL_SECTION_ID) {
      return { id: WORKING_LIST_ALL_SECTION_ID, name: "All" };
    }

    return sections.find((s) => s.id === sectionId);
  }, [sectionId, sections]);

  // Filter accounts based on the current section
  const sectionAccounts = useMemo(() => {
    if (!accounts) return [];

    if (sectionId === WORKING_LIST_ALL_SECTION_ID) {
      return accounts;
    }

    // Show accounts from the selected section
    return accounts.filter((a) => a.sectionId === sectionId);
  }, [accounts, sectionId]);

  // Additional column definitions specific to WorkingList
  const additionalColumnDefs = useMemo(() => {
    if (sectionId === WORKING_LIST_ALL_SECTION_ID) {
      return [
        {
          headerName: "Folder",
          field: "sectionName",
          minWidth: 150,
          flex: 1,
          sortable: true,
          wrapText: false,
          cellStyle: {
            textOverflow: "clip",
          },
          cellRenderer: (params) => {
            if (!params.value) return null;
            return (
              <Badge
                size="1"
                variant="outline"
                color="gray"
                mt="10px"
                radius="full"
                style={{
                  boxShadow: "none",
                  border: "1px solid var(--gray-6)",
                }}
              >
                <Folder
                  size={12}
                  color={gray.gray9}
                  strokeWidth={2}
                  style={{ flexShrink: 0 }}
                />
                <Text size="1">{params.value}</Text>
              </Badge>
            );
          },
        },
      ];
    }
    return [];
  }, [sectionId]);

  // Instead of a single "Move to..." action with submenu,
  // create a separate action for each destination section
  const getMoveActions = () => {
    if (!sections.length) return [];

    return sections.map((section) => ({
      label: `Move to: "${section.name}"`,
      icon: FolderInput,
      color: "blue",
      handler: createBulkMoveHandler(
        accounts,
        setAccounts,
        updateAccountInWorkingList,
        sections,
        section.id
      ),
    }));
  };

  // Define bulk actions for the DataGrid
  const bulkActions = useMemo(() => {
    // Only include the move actions
    return getMoveActions();
  }, [sections, accounts]);

  // Determine the appropriate empty state
  const getEmptyState = () => {
    if (!accounts || accounts.length === 0) return <ProcessingAccounts />;
    return <NoSectionAccounts />;
  };

  return (
    <TablePage
      accounts={sectionAccounts}
      setAccounts={setAccounts}
      isLoading={loading}
      icon={Flag}
      sectionTitle={"Working list"}
      pageTitle={currentSection?.name || "..."}
      additionalColumnDefs={additionalColumnDefs}
      bulkActions={bulkActions}
      emptyState={getEmptyState()}
      configKey={
        currentSection?.id && `lateral_table_config__${currentSection.id}`
      }
    />
  );
};

const AccountTable = ({
  accounts = [],
  setAccounts = null,
  isLoading = false,
  additionalColumnDefs = [],
  bulkActions = [],
  onRowSelected = null,
  configKey = null,
  filters = null,
  searchTerm = "",
  emptyState = null,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Add useEffect for escape key handler
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" && selectedAccount) {
        setSelectedAccount(null);
      }
    };

    // Add event listener for escape key
    window.addEventListener("keydown", handleEscapeKey);

    // Clean up event listener
    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [selectedAccount]);

  // Filter and search accounts
  const { results: filteredAccounts, textMatches } = useAccountFiltering(
    accounts,
    filters,
    searchTerm
  );

  // Base column definitions
  const baseColumnDefs = useMemo(() => {
    const columns = [
      {
        headerName: "Company",
        field: "name",
        minWidth: 320,
        flex: 2,
        sortable: true,
        sort: "asc",
        cellRenderer: ({ data: account, value: name }) => {
          if (!account) return null;

          return (
            <Flex align="center" gap="2" pl="2" pr="4" mt="10px">
              <HighlightedText
                matches={textMatches[account.id]?.name}
                size="2"
                weight="medium"
              >
                {name}
              </HighlightedText>
              {account.flag && <FlaggedBadge account={account} />}
              {account.needs_attention && (
                <NeedsAttentionBadge account={account} />
              )}
            </Flex>
          );
        },
      },
      {
        headerName: "Last touchpoint",
        field: "touchpoints.last_touchpoint",
        minWidth: 172,
        flex: 1,
        sortable: true,
        comparator: (valueA, valueB) => {
          const dateA = valueA?.scheduled_for
            ? new Date(valueA.scheduled_for).getTime()
            : 0;
          const dateB = valueB?.scheduled_for
            ? new Date(valueB.scheduled_for).getTime()
            : 0;
          return dateA - dateB;
        },
        cellRenderer: (params) => {
          if (!params.value) return null;
          return (
            <Flex mt="10px">
              <Touchpoint touchpoint={params.value} />
            </Flex>
          );
        },
      },
      {
        headerName: "Next touchpoint",
        field: "touchpoints.next_touchpoint",
        minWidth: 172,
        flex: 1,
        sortable: true,
        comparator: (valueA, valueB) => {
          const dateA = valueA?.scheduled_for
            ? new Date(valueA.scheduled_for).getTime()
            : 0;
          const dateB = valueB?.scheduled_for
            ? new Date(valueB.scheduled_for).getTime()
            : 0;
          return dateA - dateB;
        },
        cellRenderer: (params) => {
          if (!params.value) return null;
          return (
            <Flex mt="10px">
              <Touchpoint touchpoint={params.value} />
            </Flex>
          );
        },
      },
      {
        headerName: "Monitors",
        field: "recent_monitor_results",
        minWidth: 200,
        flex: 1,
        hide: true, // Hidden by default
        cellRenderer: (params) => {
          if (!params.value || params.value.length === 0) {
            return (
              <Text style={{ color: gray.gray10 }}>No monitor results yet</Text>
            );
          }
          return (
            <Flex direction="column" gap="2" mt="10px">
              {params.value.map((result, index) => (
                <MonitorBadge
                  key={index}
                  result={result}
                  account={params.data}
                />
              ))}
            </Flex>
          );
        },
      },
      {
        headerName: "Highlights",
        field: "recent_activity",
        minWidth: 250,
        flex: 1.5,
        hide: true, // Hidden by default
        cellRenderer: (params) => {
          if (!params.value || params.value.length === 0) {
            return (
              <Text style={{ color: gray.gray10 }}>No highlights yet</Text>
            );
          }
          return (
            <Flex direction="column" gap="2" mt="10px">
              {params.value.map((activity, index) => (
                <Flex
                  key={index}
                  align="top"
                  gap="2"
                  style={{ color: gray.gray12 }}
                >
                  <Text size="1" mt="2px">
                    {activity.emoji_label}
                  </Text>
                  <Text wrap="pretty" size="2">
                    {activity.insight}
                  </Text>
                </Flex>
              ))}
            </Flex>
          );
        },
      },
      {
        headerName: "Notes",
        field: "notes",
        minWidth: 100,
        flex: 1,
        sortable: false,
        cellRenderer: ({ value: notes }) => {
          if (!notes || notes.length === 0) return null;

          const latestNote = notes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )[0];

          const seeMoreCount = notes.length - 1;

          return (
            <Flex direction="column" gap="1" mt="10px">
              <Text>{latestNote.note}</Text>
              {seeMoreCount > 0 && (
                <Text size="1" style={{ color: gray.gray9 }}>
                  + {seeMoreCount} more
                </Text>
              )}
            </Flex>
          );
        },
      },
      // {
      //   headerName: "Tasks",
      //   field: "tasks",
      //   minWidth: 100,
      //   flex: 1,
      //   sortable: true,
      //   cellRenderer: (params) => {
      //     return <Text>{params.value}</Text>;
      //   },
      // },
    ];

    // Dynamically add columns from account_details if we have accounts
    if (accounts?.length > 0) {
      // Find the first account with details
      const accountWithDetails = accounts.find(
        (a) => a.account_details && Object.keys(a.account_details).length > 0
      );

      if (accountWithDetails?.account_details) {
        // Create a column for each detail field
        const detailColumns = Object.entries(
          accountWithDetails.account_details
        ).map(([key, detail]) => {
          // Create different column configs based on detail type
          if (detail.type === "datetime") {
            return {
              headerName: key,
              field: `account_details.${key}.value`,
              minWidth: 140,
              flex: 1,
              sortable: true,
              comparator: (valueA, valueB) => {
                const dateA = valueA ? new Date(valueA).getTime() : 0;
                const dateB = valueB ? new Date(valueB).getTime() : 0;
                return dateA - dateB;
              },
              cellRenderer: (params) => {
                if (!params.value) return null;
                return (
                  <Tooltip content={prettifyDate(new Date(params.value), true)}>
                    <Text>{toTimeAgo(new Date(params.value))}</Text>
                  </Tooltip>
                );
              },
            };
          } else if (detail.type === "list") {
            return {
              headerName: key,
              field: `account_details.${key}.value`,
              minWidth: 150,
              flex: 1,
              sortable: true,
              cellRenderer: (params) => {
                if (!params.value) return null;
                const items = params.value.split(";");
                return (
                  <Flex gap="1" wrap="wrap" mt="10px">
                    {items.map((item, i) => (
                      <Badge key={i} size="1" variant="soft" color="gray">
                        {item.trim()}
                      </Badge>
                    ))}
                  </Flex>
                );
              },
            };
          } else {
            // Default for other types
            return {
              headerName: key,
              field: `account_details.${key}.value`,
              minWidth: 150,
              flex: 1,
              sortable: true,
            };
          }
        });

        // Add detail columns after the company column
        columns.splice(1, 0, ...detailColumns);
      }
    }

    return columns;
  }, [accounts, textMatches]);

  // Combine base columns with additional columns
  const columnDefs = useMemo(() => {
    return [...baseColumnDefs, ...additionalColumnDefs];
  }, [baseColumnDefs, additionalColumnDefs]);

  // Handle row click
  const handleRowClicked = (params) => {
    if (selectedAccount && selectedAccount.id === params.data.id) {
      // If clicking the same row that's already selected, close the sidebar
      setSelectedAccount(null);
    } else {
      // Otherwise, select this row and open the sidebar
      setSelectedAccount(params.data);
    }

    // Call external handler if provided
    if (onRowSelected) {
      onRowSelected(params.data);
    }
  };

  // Handle closing the sidebar
  const handleCloseSidebar = () => {
    setSelectedAccount(null);
  };

  // AG-Grid options
  const gridOptions = {
    rowHeight: 100,
    headerHeight: 32,
    rowSelection:
      bulkActions.length > 0
        ? {
            mode: "multiRow",
            rowDeselectable: true,
          }
        : false,
    suppressCellFocus: true,
    // Enable sorting
    suppressMenuHide: false,
    suppressScrollOnNewData: true,
    animateRows: true,
    onRowClicked: handleRowClicked,
  };

  const isSidebarOpen = selectedAccount !== null;

  // Render appropriate content based on state
  const renderContent = () => {
    if (isLoading) return <Loading />;
    if (!accounts || accounts.length === 0)
      return emptyState || <ProcessingAccounts />;
    if (filteredAccounts.length === 0) return <NoSearchResults />;

    return (
      <Flex style={{ height: "calc(100vh - 80px)" }}>
        <Flex
          style={{
            width: "100%",
            transition: "width 0.3s ease",
          }}
        >
          <DataGrid
            rowData={filteredAccounts}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            height="100%"
            bulkActions={bulkActions}
            activeRowId={selectedAccount?.id}
            configKey={configKey}
            disableSorting={!!searchTerm}
          />
        </Flex>

        {isSidebarOpen && (
          <AccountSidebar
            account={selectedAccount}
            onNoteAdded={(newNote) => {
              setSelectedAccount((prevAccount) => ({
                ...prevAccount,
                notes: [...prevAccount.notes, newNote],
              }));
              setAccounts((prevAccounts) =>
                prevAccounts.map((account) =>
                  account.id === selectedAccount.id
                    ? { ...account, notes: [...account.notes, newNote] }
                    : account
                )
              );
            }}
            onClose={handleCloseSidebar}
          />
        )}
      </Flex>
    );
  };

  return renderContent();
};

export { WorkingList, AllAccountsList, MyBookList };
