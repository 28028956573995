import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { violet } from "@radix-ui/colors";
import { Check } from "lucide-react";

import ReactSelect, { components } from "react-select";

const SelectContext = createContext();

const Root = ({
  children,
  value,
  onValueChange,
  multiple = false,
  disabled = false,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);

  const handleChange = (newValue) => {
    if (!onValueChange || disabled) return;

    // Handle null/undefined case
    if (!newValue) {
      onValueChange(multiple ? [] : null);
      return;
    }

    // Handle multi-select case
    if (multiple) {
      const valueArray = Array.isArray(newValue) ? newValue : [newValue];
      const values = valueArray.map((option) => option.value);
      onValueChange(values);
    } else {
      // Handle single select case
      onValueChange(newValue.value);
    }

    // Only close menu on single select
    if (!multiple) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SelectContext.Provider
      value={{
        isOpen,
        setIsOpen,
        value,
        onChange: handleChange,
        triggerRef,
        multiple,
        disabled,
      }}
    >
      <div className={className} style={{ position: "relative" }}>
        {children}
      </div>
    </SelectContext.Provider>
  );
};

const Trigger = ({ children }) => {
  const { isOpen, setIsOpen, triggerRef, disabled } = useContext(SelectContext);

  return (
    <div
      ref={triggerRef}
      onClick={() => !disabled && setIsOpen(!isOpen)}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
        userSelect: "none",
      }}
    >
      {children}
    </div>
  );
};

const Content = ({ children }) => {
  const { isOpen, value, onChange, triggerRef, multiple } =
    useContext(SelectContext);

  if (!isOpen) return null;

  const options = React.Children.map(children, (child) => ({
    value: child.props.value,
    label: child.props.children,
  }));

  const customStyles = {
    control: () => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      padding: "4px 8px",
      position: "absolute",
      width: "auto",
      minWidth: triggerRef.current ? triggerRef.current.offsetWidth : "auto",
      transform: `translateY(${
        triggerRef.current ? triggerRef.current.offsetHeight : 0
      }px)`,
      left: 0,
    }),
    option: (provided, state) => {
      const { isSelected, selectProps } = state;
      if (selectProps.isMulti) {
        return {
          ...provided,
          backgroundColor: isSelected ? violet.violet2 : "white",
          color: isSelected ? violet.violet11 : "black",
          padding: "6px 16px",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "&:hover": {
            backgroundColor: isSelected ? violet.violet2 : "#f0f0f0",
          },
        };
      } else {
        return {
          ...provided,
          backgroundColor: isSelected ? "#6E56CF" : "white",
          color: isSelected ? "white" : "black",
          padding: "6px 16px",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "14px",
          "&:hover": {
            backgroundColor: isSelected ? "#6E56CF" : "#f0f0f0",
          },
          "& svg": {
            stroke: isSelected && "white",
          },
        };
      }
    },
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#F4EBFF",
      borderRadius: "4px",
      margin: "2px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#6E56CF",
      fontSize: "14px",
      padding: "2px 6px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#6E56CF",
      ":hover": {
        backgroundColor: "#E4DAFF",
        color: "#6E56CF",
      },
    }),
  };

  const selectValue = multiple
    ? options.filter(
        (option) => Array.isArray(value) && value.includes(option.value)
      )
    : options.find((option) => option.value === value);

  const selectProps = {
    value: selectValue,
    onChange,
    isMulti: multiple,
    closeMenuOnSelect: !multiple,
    options,
    styles: customStyles,
    menuIsOpen: true,
    autoFocus: true,
    backspaceRemovesValue: true,
    components: { Option: Item },
    controlShouldRenderValue: false,
    hideSelectedOptions: false,
    isClearable: false,
    menuShouldBlockScroll: false,
    menuShouldScrollIntoView: false,
    placeholder: "Search...",
    tabSelectsValue: false,
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        width: triggerRef.current ? triggerRef.current.offsetWidth : "auto",
        height: triggerRef.current ? triggerRef.current.offsetHeight : "auto",
      }}
    >
      <ReactSelect {...selectProps} />
    </div>
  );
};

const Item = (props) => {
  const { children, isSelected, selectProps } = props;
  return (
    <components.Option {...props} className={isSelected ? "selected" : ""}>
      {children}
      {selectProps.isMulti && isSelected && (
        <Check size={16} color={violet.violet10} style={{ flexShrink: 0 }} />
      )}
    </components.Option>
  );
};

const Select = {
  Root,
  Trigger,
  Content,
  Item,
};

export default Select;
