import React, { useCallback, useRef, useEffect, useMemo } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { TextField, Text, Flex } from "@radix-ui/themes";
import Fuse from "fuse.js";
import { gray } from "@radix-ui/colors";

// Default configuration for Fuse.js
const DEFAULT_FUSE_OPTIONS = {
  threshold: 0.4,
  ignoreLocation: true,
  includeMatches: true,
  minMatchCharLength: 2,
};

// Renders text with highlighted matches
export const HighlightedText = ({ children, matches = [], ...props }) => {
  if (!matches.length) return <Text {...props}>{children}</Text>;

  const content = children?.toString() ?? "";
  const segments = getTextSegments(content, matches);

  return (
    <Text>
      {segments.map((segment, index) => (
        <TextSegment key={index} {...segment} {...props} />
      ))}
    </Text>
  );
};

const TextSegment = ({ text, highlight, ...props }) => (
  <Text
    {...props}
    style={{
      whiteSpace: "pre",
      ...(highlight && {
        backgroundColor: "var(--yellow-a3)",
        borderRadius: 3,
      }),
    }}
  >
    {text}
  </Text>
);

const getTextSegments = (content, matches) => {
  // Sort and merge overlapping ranges
  const ranges = matches
    .sort((a, b) => a[0] - b[0])
    .reduce((acc, [start, end]) => {
      const prev = acc[acc.length - 1];
      if (prev && start <= prev[1] + 1) {
        prev[1] = Math.max(prev[1], end);
      } else {
        acc.push([start, end]);
      }
      return acc;
    }, []);

  // Convert ranges to segments
  return ranges.reduce((segments, [start, end], index) => {
    // Add non-highlighted text before match
    if (index === 0 && start > 0) {
      segments.push({ text: content.slice(0, start), highlight: false });
    }

    // Add highlighted text
    segments.push({ text: content.slice(start, end + 1), highlight: true });

    // Add non-highlighted text between matches or after last match
    const nextStart = ranges[index + 1]?.[0];
    const endIndex = nextStart ?? content.length;
    if (end + 1 < endIndex) {
      segments.push({
        text: content.slice(end + 1, endIndex),
        highlight: false,
      });
    }

    return segments;
  }, []);
};

// Helper hook to create a reusable fuzzy search instance
export const useFuzzySearch = (items = [], searchKeys, options = {}) => {
  const fuse = useMemo(() => {
    return new Fuse(items, {
      ...DEFAULT_FUSE_OPTIONS,
      ...options,
      keys: searchKeys,
    });
  }, [items, searchKeys, options]);

  const search = useCallback(
    (searchTerm) => {
      if (!searchTerm?.trim()) {
        return { results: items, textMatches: {} };
      }

      const searchResults = fuse.search(searchTerm);

      return {
        results: searchResults.map(({ item }) => item),
        textMatches: buildMatchesMap(searchResults),
      };
    },
    [fuse, items]
  );

  return search;
};

// Transform matches into a more usable format
const buildMatchesMap = (searchResults) => {
  const matchesMap = {};

  for (const { item, matches } of searchResults) {
    matchesMap[item.id] = {};

    for (const { key, indices, refIndex } of matches) {
      matchesMap[item.id][createMatchKey(key, refIndex)] = indices;
    }
  }

  return matchesMap;
};

const createMatchKey = (key, refIndex) => {
  if (!key.includes(".") || refIndex === undefined) return key;

  // Handle array matches (e.g., recent_activity.0.insight)
  const [arrayKey, ...rest] = key.split(".");
  return `${arrayKey}.${refIndex}.${rest.join(".")}`;
};

// Hook for keyboard shortcut (cmd + k)
const useKeyboardShortcut = (inputRef, enabled) => {
  useEffect(() => {
    if (!enabled) return;

    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [inputRef, enabled]);
};

const Search = ({
  value,
  onChange,
  placeholder = "Search...",
  disabled,
  style,
  shortcutEnabled = false,
}) => {
  const inputRef = useRef(null);
  useKeyboardShortcut(inputRef, shortcutEnabled);

  return (
    <TextField.Root
      size="2"
      placeholder={placeholder}
      style={{
        width: "224px",
        boxShadow: "none",
        border: "1px solid var(--gray-4)",
        borderRadius: "8px",
        ...style,
      }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      ref={inputRef}
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
      {shortcutEnabled && (
        <TextField.Slot>
          <Text mr="1" style={{ color: gray.gray8 }}>
            ⌘K
          </Text>
        </TextField.Slot>
      )}
    </TextField.Root>
  );
};

export default Search;
