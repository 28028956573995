import { useState } from "react";
import { useMonitor } from "../context/MonitorContext";
import { useAccount } from "../context/AccountContext";

const NUM_ACCOUNTS_TO_TEST = 5;

const MonitorResult = {
  base: (account) => ({
    account,
    monitor_answer: {
      detected: false,
      rationale: "",
      evidence: "",
      loading: false,
    },
  }),

  loading: (account) => ({
    ...MonitorResult.base(account),
    monitor_answer: {
      ...MonitorResult.base(account).monitor_answer,
      loading: true,
    },
  }),

  error: (account, error) => ({
    ...MonitorResult.base(account),
    monitor_answer: {
      ...MonitorResult.base(account).monitor_answer,
      rationale:
        "Something went wrong while analyzing this account. A Lateral engineer has been notified and is looking into it.",
      error: error.message,
    },
  }),

  success: (account, answer) => ({
    ...MonitorResult.base(account),
    monitor_answer: { ...answer },
  }),
};

const pickRandomAccounts = (accounts, count = NUM_ACCOUNTS_TO_TEST) => {
  return accounts.sort(() => Math.random() - 0.5).slice(0, count);
};

export const useMonitorTesting = () => {
  const { getAccounts } = useAccount();
  const { testDraftMonitor } = useMonitor();
  const [results, setResults] = useState(null);

  const runTestOnAccount = async (
    account,
    prompt,
    customRecordType,
    canonicalRecordType
  ) => {
    try {
      const data = await testDraftMonitor(
        prompt,
        customRecordType,
        canonicalRecordType,
        account.id
      );
      return MonitorResult.success(account, data.monitor_answer);
    } catch (error) {
      return MonitorResult.error(account, error);
    }
  };

  const testMonitor = async (prompt, selectedRecordTypes, filters) => {
    const accountsToTest = await getAccounts(filters.assignedTo).then(
      pickRandomAccounts
    );
    if (!accountsToTest.length) return;

    // Initialize loading state
    setResults(accountsToTest.map(MonitorResult.loading));

    // Process accounts and update results as they complete
    return accountsToTest.map(async (account, index) => {
      const result = await runTestOnAccount(
        account,
        prompt,
        selectedRecordTypes.custom[0],
        selectedRecordTypes.canonical[0]
      );
      setResults((prev) =>
        prev?.map((item, i) => (i === index ? result : item))
      );
      return result;
    });
  };

  return {
    results,
    isTesting:
      results?.some((result) => result.monitor_answer.loading) ?? false,
    testMonitor,
    resetResults: () => setResults(null),
  };
};
