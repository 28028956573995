import React from "react";
import { Flex, Spinner, Text } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import { RefreshCcw, SearchX } from "lucide-react";

const EmptyState = ({
  title,
  description,
  icon: Icon,
  maxWidth = 256,
  children,
}) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    py="9"
    style={{ height: "100%" }}
  >
    <Icon height={120} width={120} strokeWidth={1.25} color={gray.gray7} />
    <Flex
      direction="column"
      align="center"
      gap="1"
      mt="2"
      style={{ maxWidth, width: "100%" }}
    >
      <Text size="4" weight="medium" align="center">
        {title}
      </Text>
      <Text size="2" align="center" style={{ color: gray.gray9 }}>
        {description}
      </Text>
    </Flex>
    {children}
  </Flex>
);

export const Loading = () => (
  <Flex justify="center" align="center" style={{ height: "100%" }}>
    <Flex direction="column" align="center" gap="4">
      <Spinner size="3" style={{ transform: "scale(1.5)" }} />
      <Text size="2" weight="medium" style={{ color: gray.gray10 }}>
        Loading...
      </Text>
    </Flex>
  </Flex>
);

export const NoSearchResults = ({ itemType = "accounts" }) => (
  <EmptyState
    title={`No matching ${itemType} found`}
    description="Try adjusting your search"
    icon={SearchX}
  />
);

export const ProcessingAccounts = () => (
  <EmptyState
    title="Getting everything ready..."
    description="We're currently processing your accounts. Please check back in a few hours."
    icon={RefreshCcw}
    maxWidth={320}
  />
);

export default EmptyState;
