import React, { useState } from "react";
import {
  Flex,
  Button,
  TextField,
  Heading,
  ScrollArea,
  Text,
} from "@radix-ui/themes";
import { Cpu } from "lucide-react";

import { useDevTools } from "./context/DevToolsContext";
import Toolbar from "./Toolbar";

const PageContainer = ({ children }) => (
  <Flex direction="column">
    <Toolbar icon={Cpu} sectionTitle="Internal" pageTitle="Developer tools" />
    <ScrollArea
      scrollbars="vertical"
      type="scroll"
      style={{ height: "calc(100vh - 80px)" }}
    >
      <Flex direction="column" px="5" py="4">
        {children}
      </Flex>
    </ScrollArea>
  </Flex>
);

const Setting = ({
  title,
  description,
  currentValue,
  onSave,
  label,
  placeholder,
}) => {
  const [value, setValue] = useState(currentValue || "");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(value || null);
  };

  return (
    <Flex direction="column">
      <Heading size="3" weight="medium" mb="1">
        {title}
      </Heading>
      <Text size="2" color="gray" mb="4">
        {description}
      </Text>

      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap="3">
          <Flex direction="column" gap="1">
            <Text size="2" weight="medium" color="gray">
              {label}
            </Text>
            <TextField.Root
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder}
              style={{ width: "100%", maxWidth: "300px" }}
            />
          </Flex>
          <Button
            disabled={
              (!currentValue && !value) ||
              (value && currentValue && value === currentValue)
            }
            type="submit"
            style={{ width: "fit-content" }}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

const DevToolsPage = () => {
  const { bookOfBusinessUserId, setBookOfBusiness } = useDevTools();

  return (
    <PageContainer>
      <Setting
        title="Fetch book of business for user"
        description="Enter the external_id of a user to fetch their specific book of business. This will load all accounts and needs attention under their purview."
        currentValue={bookOfBusinessUserId}
        onSave={setBookOfBusiness}
        label="User ID:"
        placeholder="Enter user's external_id (eg. user_1234567890)"
      />
    </PageContainer>
  );
};

export default DevToolsPage;
