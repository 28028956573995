import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Text,
  Flex,
  Spinner,
  Button,
  ScrollArea,
  Table,
  Badge,
  Popover,
  TextField,
} from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import {
  SearchX,
  Radar,
  Plus,
  Database,
  Telescope,
  FileOutput,
  FileDown,
  Share,
  Download,
  ChevronDown,
} from "lucide-react";

import { useMonitor, MONITOR_FREQUENCY_HOURS } from "./context/MonitorContext";
import Toolbar from "./Toolbar";
import SortableTable from "./SortableTable";
import EmptyState from "./EmptyState";
import { capitalize, hoursToFrequency, toTimeAgo } from "./utils";
import MonitorsIcon from "./assets/monitors.svg";
import BG from "./assets/bg-accent.png";
import "./MonitorList.css";
import Select from "./Select";

const HeroContainer = ({ children }) => (
  <Flex
    direction="column"
    align="center"
    gap="4"
    py="56px"
    style={{
      width: "100%",
      background: `url(${BG}), linear-gradient(302deg, #F5F2FF 4.01%, #FDFCFE 90.89%)`,
      backgroundPosition: "right calc(100% + 536px), center",
      backgroundSize: "535px 984px, 100% 100%",
      backgroundBlendMode: "darken, normal",
      backgroundRepeat: "no-repeat, no-repeat",
    }}
  >
    {children}
  </Flex>
);

const IntroHeader = () => (
  <Flex direction="column" align="center" style={{ maxWidth: 648 }}>
    <img
      src={MonitorsIcon}
      alt="Signal"
      draggable={false}
      style={{ width: 80, height: 80, userSelect: "none" }}
    />
    <Text
      as="h2"
      size="8"
      weight="medium"
      align="center"
      mt="5"
      mb="2"
      style={{ letterSpacing: "-1.5px" }}
    >
      Continuously monitor your accounts
    </Text>
    <Text size="4" align="center" style={{ color: gray.gray10 }}>
      Monitors run on your accounts to help you detect important patterns and
      changes in your customer's behavior that might otherwise go unnoticed
    </Text>
  </Flex>
);

const MonitorRow = ({ monitor, onRowClick }) => {
  return (
    <Table.Row
      className="table-row"
      onClick={() => onRowClick(monitor.id)}
      style={{ cursor: "pointer" }}
    >
      <Table.Cell>
        <Flex direction="column" gap="1" ml="3" mr="4" minWidth="300px">
          <Flex direction="column" gap="2px">
            <Text size="3" weight="medium">
              {monitor.label}
            </Text>
            <Text
              title={monitor.prompt}
              size="2"
              style={{ color: gray.gray10 }}
              // truncate
              // style={{ maxWidth: 860 }}
            >
              {monitor.prompt}
            </Text>
          </Flex>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex direction="column" gap="1">
          {monitor.recent_results_summary.evaluated_at ? (
            <>
              <Text size="2" wrap="nowrap">
                {monitor.recent_results_summary.detected_count} accounts matched
              </Text>
              <Text size="1" style={{ color: gray.gray9 }}>
                {toTimeAgo(monitor.recent_results_summary.evaluated_at)}
              </Text>
            </>
          ) : (
            <>
              <Text size="2" style={{ color: gray.gray9 }}>
                Awaiting initial run...
              </Text>
              <Text size="1" style={{ color: gray.gray9 }}>
                Scheduled for tonight
              </Text>
            </>
          )}
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex align="center" gap="6px">
          <Database size={12} color={gray.gray10} />
          <Text wrap="nowrap">
            {monitor.custom_record_definition?.name ||
              monitor.canonical_record_type?.name}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Text size="2">{hoursToFrequency(MONITOR_FREQUENCY_HOURS)}</Text>
      </Table.Cell>
      <Table.Cell>
        <Flex direction="column" gap="1">
          <Text size="2" wrap="nowrap">
            {toTimeAgo(monitor.created_at)}
          </Text>
          {/* <Text size="1" style={{ color: gray.gray9 }}>
            {monitor.created_by}
          </Text> */}
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

const columns = [
  {
    key: "prompt",
    label: "Monitor",
    width: "50%",
    sortFn: (a, b) => b.prompt.localeCompare(a.prompt),
  },
  {
    key: "recent_results_summary.detected_count",
    label: "Latest run",
    width: "15%",
    sortFn: (a, b) => {
      const aVal = a.recent_results_summary?.detected_count || 0;
      const bVal = b.recent_results_summary?.detected_count || 0;
      return aVal - bVal;
    },
  },
  {
    label: "Data source",
    width: "15%",
  },
  {
    key: "details.run_frequency",
    label: "Frequency",
    sortFn: (a, b) => {
      const aVal = a.details?.run_frequency || "";
      const bVal = b.details?.run_frequency || "";
      return aVal.localeCompare(bVal);
    },
  },
  {
    key: "created_at",
    label: "Created",
    sortFn: (a, b) => new Date(a.created_at) - new Date(b.created_at),
  },
];

const MonitorsTable = ({ monitors, onRowClick }) => (
  <SortableTable
    data={monitors}
    columns={columns}
    defaultSortKey="created_at"
    rowRenderer={(monitor) => (
      <MonitorRow key={monitor.id} monitor={monitor} onRowClick={onRowClick} />
    )}
  />
);

const ExportPopover = ({ monitors }) => {
  const { exportMonitorResults } = useMonitor();
  const [selectedMonitors, setSelectedMonitors] = useState([]);
  const [lookbackDays, setLookbackDays] = useState(7);
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    setIsExporting(true);
    try {
      // If no monitors selected, use all monitors
      const monitorIds =
        selectedMonitors.length > 0
          ? selectedMonitors
          : monitors.map((m) => m.id);

      await exportMonitorResults(monitorIds, lookbackDays);
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setIsExporting(false);
    }
  };

  const monitorOptions = monitors.map((monitor) => ({
    value: monitor.id,
    label: monitor.label || `Untitled (${monitor.id})`,
  }));

  const lookbackOptions = [
    { value: 1, label: "Last 24 hours" },
    { value: 3, label: "Last 3 days" },
    { value: 7, label: "Last week" },
    { value: 30, label: "Last month" },
    { value: 90, label: "Last 3 months" },
  ];

  const isDisabled = monitors.length === 0 || isExporting;

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button size="2" radius="large" variant="outline">
          <Download size={16} />
          Export
          <ChevronDown strokeWidth={2.25} size={12} style={{ opacity: 0.75 }} />
        </Button>
      </Popover.Trigger>
      <Popover.Content
        width="288px"
        size="2"
        align="end"
        sideOffset={10}
        style={{ overflow: "visible" }}
      >
        <Flex direction="column" gap="4" p="2">
          {/* Header */}
          <Flex direction="column" gap="3">
            <Text size="5" weight="medium">
              Export monitor results
            </Text>
            <Text size="2" style={{ color: gray.gray10 }}>
              Download historical results of your monitors as a CSV file.
            </Text>
          </Flex>

          {/* Select monitors */}
          <Flex direction="column" gap="1">
            <Text size="2" weight="medium">
              Monitors
            </Text>
            <Select.Root
              multiple={true}
              value={selectedMonitors}
              onValueChange={setSelectedMonitors}
              disabled={isDisabled}
            >
              <Select.Trigger>
                <Flex
                  align="center"
                  justify="between"
                  pl="3"
                  pr="2"
                  style={{
                    border: `1px solid ${gray.gray7}`,
                    borderRadius: 4,
                    padding: "4px 12px",
                    color: isDisabled ? gray.gray10 : gray.gray12,
                    minHeight: "32px",
                  }}
                >
                  <Text size="2">
                    {selectedMonitors.length > 0
                      ? selectedMonitors.length === 1
                        ? monitorOptions.find(
                            (option) => option.value === selectedMonitors[0]
                          )?.label || "1 selected"
                        : `${selectedMonitors.length} selected`
                      : "All"}
                  </Text>
                  <ChevronDown
                    size={14}
                    color={gray.gray8}
                    style={{ flexShrink: 0 }}
                  />
                </Flex>
              </Select.Trigger>
              <Select.Content position="popper">
                {monitorOptions.map((option) => (
                  <Select.Item key={option.value} value={option.value}>
                    {option.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Flex>

          {/* Lookback period */}
          <Flex direction="column" gap="1">
            <Text size="2" weight="medium">
              Time period
            </Text>
            <Select.Root
              value={lookbackDays}
              onValueChange={setLookbackDays}
              disabled={isDisabled}
            >
              <Select.Trigger>
                <Flex
                  align="center"
                  justify="between"
                  pl="3"
                  pr="2"
                  style={{
                    border: `1px solid ${gray.gray7}`,
                    borderRadius: 4,
                    padding: "4px 12px",
                    color: isDisabled ? gray.gray10 : gray.gray12,
                    minHeight: "32px",
                  }}
                >
                  <Text size="2">
                    {lookbackOptions.find(
                      (option) => option.value === lookbackDays
                    )?.label || "Last week"}
                  </Text>
                  <ChevronDown size={14} color={gray.gray8} />
                </Flex>
              </Select.Trigger>
              <Select.Content position="popper">
                {lookbackOptions.map((option) => (
                  <Select.Item key={option.value} value={option.value}>
                    {option.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Flex>

          {/* Footer */}
          <Flex justify="end" gap="3" mt="2">
            {/* <Popover.Close>
              <Button variant="soft" size="2" radius="large" color="gray">
                Cancel
              </Button>
            </Popover.Close> */}
            <Button
              size="2"
              radius="large"
              onClick={handleExport}
              disabled={isExporting}
              style={{ width: "100%" }}
            >
              {isExporting ? (
                <>
                  <Spinner size="1" />
                  Processing...
                </>
              ) : (
                <>
                  <FileDown size={16} />
                  Download CSV
                </>
              )}
            </Button>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};

const MonitorListContainer = ({ title, children, monitors }) => {
  const navigate = useNavigate();

  return (
    <Flex className="monitor-list-container" direction="column">
      <Toolbar
        icon={Radar}
        sectionTitle="Monitors"
        pageTitle={title}
        primaryAction={
          <Flex align="center" gap="3">
            <ExportPopover monitors={monitors || []} />
            <Button
              size="2"
              radius="large"
              onClick={() => navigate("/monitors/create")}
            >
              <Plus size={16} />
              Create
            </Button>
          </Flex>
        }
      />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        {children}
      </ScrollArea>
    </Flex>
  );
};

const Loading = ({ currentTab }) => (
  <MonitorListContainer title={capitalize(currentTab)}>
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <Flex direction="column" align="center" gap="4">
        <Spinner size="3" style={{ transform: "scale(1.5)" }} />
        <Text size="2" weight="medium" style={{ color: gray.gray10 }}>
          Loading...
        </Text>
      </Flex>
    </Flex>
  </MonitorListContainer>
);

const NoMonitors = () => {
  const navigate = useNavigate();
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "calc(100% - 300px)",
        borderTop: `1px solid ${gray.gray6}`,
      }}
    >
      <EmptyState
        title="Create your first monitor"
        description="You don't have any monitors yet. When you create one, it will appear here."
        icon={Telescope}
        maxWidth={280}
      >
        <Button
          size="2"
          radius="large"
          variant="outline"
          mt="5"
          onClick={() => navigate("/monitors/create")}
        >
          <Plus size={16} />
          New monitor
        </Button>
      </EmptyState>
    </Flex>
  );
};

function MonitorList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getMonitors } = useMonitor();
  const [monitorsData, setMonitorsData] = useState(null);

  const currentTab = location.pathname.split("/").pop();

  useEffect(() => {
    getMonitors().then(setMonitorsData);
  }, [getMonitors]);

  const filteredMonitors = useMemo(() => {
    if (!monitorsData) return [];
    // return monitorsData.filter(
    //   (monitor) => monitor.monitor_access === currentTab
    // );
    return monitorsData;
  }, [monitorsData, currentTab]);

  if (!monitorsData) return <Loading currentTab={currentTab} />;

  const hasMonitors = filteredMonitors.length > 0;

  const renderContent = () => {
    if (!hasMonitors) return <NoMonitors />;

    return filteredMonitors.length > 0 ? (
      <MonitorsTable
        monitors={filteredMonitors}
        onRowClick={(id) => navigate(`/monitors/${id}`)}
      />
    ) : (
      <EmptyState
        title="No matching monitors found"
        description="Try adjusting your search"
        icon={SearchX}
      />
    );
  };

  return (
    <MonitorListContainer
      title={capitalize(currentTab)}
      monitors={monitorsData}
    >
      <HeroContainer>
        <IntroHeader />
      </HeroContainer>
      {renderContent()}
    </MonitorListContainer>
  );
}

export default MonitorList;
