import React from "react";
import ReactDOM from "react-dom/client";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import "inter-ui/inter.css";

import { AuthProvider } from "./context/AuthContext";
import Analytics, { initializeSentry } from "./Analytics";
import App from "./App";
import "./theme-config.css";
import "./index.css";

initializeSentry();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Theme accentColor="violet" grayColor="mauve" panelBackground="solid">
      <AuthProvider>
        <Analytics />
        <App />
      </AuthProvider>
    </Theme>
  </>
);
