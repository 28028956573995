import React, { useState, useMemo, useCallback } from "react";
import { Table, Flex, Text } from "@radix-ui/themes";
import { violet } from "@radix-ui/colors";
import { ArrowUpWideNarrow, ArrowDownWideNarrow } from "lucide-react";

import "./SortableTable.css";

export const useSorter = (
  initialData,
  columns,
  defaultKey = "",
  defaultDirection = "desc",
  disabled = false
) => {
  const [sortBy, setSortBy] = useState(defaultKey);
  const [sortDirection, setSortDirection] = useState(defaultDirection);

  const sortedData = useMemo(() => {
    if (disabled || !sortBy) return initialData;

    const column = columns.find((col) => col.key === sortBy);
    if (!column?.sortFn) return initialData;

    return [...initialData].sort((a, b) =>
      sortDirection === "asc" ? column.sortFn(a, b) : column.sortFn(b, a)
    );
  }, [initialData, columns, sortBy, sortDirection, disabled]);

  const onSort = useCallback(
    (key) => {
      if (disabled) return;

      if (sortBy === key) {
        setSortDirection((current) => (current === "asc" ? "desc" : "asc"));
      } else {
        setSortBy(key);
        setSortDirection(defaultDirection);
      }
    },
    [sortBy, defaultDirection, disabled]
  );

  return {
    sortedData,
    sortBy,
    sortDirection,
    onSort,
    sortingDisabled: disabled,
  };
};

export const SortableColumnHeader = ({
  column,
  sortBy,
  sortDirection,
  onSort,
  sortingDisabled,
}) => {
  const isSortable = typeof column.sortFn === "function" && !sortingDisabled;
  const isCurrent = isSortable && column.key === sortBy;
  const SortDirectionIcon =
    sortDirection === "asc" ? ArrowUpWideNarrow : ArrowDownWideNarrow;

  return (
    <Table.ColumnHeaderCell
      style={{ width: column.width }}
      className={column.hideDivider ? "hide-divider" : ""}
    >
      <Flex
        align="center"
        gap="2"
        className={`column-header ${isSortable ? "sortable" : ""} ${isCurrent ? "current" : ""}`}
        onClick={() => isSortable && onSort(column.key)}
      >
        <Text wrap="nowrap">{column.label}</Text>
        {isCurrent && (
          <SortDirectionIcon
            size={12}
            strokeWidth={1.5}
            color={violet.violet10}
          />
        )}
      </Flex>
    </Table.ColumnHeaderCell>
  );
};

const SortableTable = ({
  data,
  columns,
  defaultSortKey = "",
  defaultSortDirection = "desc",
  disableSorting = false,
  rowRenderer,
  tableProps = {},
  headerStyle = {},
}) => {
  const { sortedData, ...sortProps } = useSorter(
    data,
    columns,
    defaultSortKey,
    defaultSortDirection,
    disableSorting
  );

  return (
    <Table.Root
      variant="surface"
      {...tableProps}
      className={`sortable-table ${tableProps.className || ""}`}
    >
      <Table.Header style={headerStyle}>
        <Table.Row>
          {columns.map((column, index) => (
            <SortableColumnHeader key={index} column={column} {...sortProps} />
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedData.map((item, index) => rowRenderer(item, index))}
      </Table.Body>
    </Table.Root>
  );
};

export default SortableTable;
