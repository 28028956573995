import { useState } from "react";
import {
  Flex,
  Text,
  Button,
  Separator,
  ScrollArea,
  TextArea,
  DataList,
  Link,
  Switch,
  Tooltip,
} from "@radix-ui/themes";
import { gray, violet } from "@radix-ui/colors";
import {
  Building2,
  ListTodo,
  NotebookPen,
  Shapes,
  MessagesSquare,
  Activity,
  X,
  Plus,
  Radar,
  ChevronDown,
  Globe,
  Lock,
  Users,
} from "lucide-react";

import { useAuth } from "./context/AuthContext";
import { useAccount } from "./context/AccountContext";
import { Touchpoint } from "./AccountList";
import MonitorBadge from "./MonitorBadge";
import { prettifyDate, toFullDate, toTimeAgo } from "./utils";
import "./AccountSidebar.css";

const ExpandableList = ({
  items,
  initialCount = 5,
  renderItem,
  emptyMessage,
  isExpanded,
  onExpand,
}) => {
  if (!items?.length) {
    return (
      <Text size="2" style={{ color: gray.gray10 }}>
        {emptyMessage}
      </Text>
    );
  }

  const visibleItems = isExpanded ? items : items.slice(0, initialCount);
  const hiddenItemCount = items.length - initialCount;
  const hasHiddenItems = !isExpanded && hiddenItemCount > 0;

  return (
    <Flex direction="column" gap="2">
      {visibleItems.map(renderItem)}

      {hasHiddenItems && (
        <Link
          className="expandable-list-link"
          size="1"
          my="2px"
          onClick={onExpand}
        >
          <Flex align="center" gap="6px">
            <ChevronDown size={12} strokeWidth={2} />
            {`See ${hiddenItemCount} more`}
          </Flex>
        </Link>
      )}
    </Flex>
  );
};

const AdditionalDetails = ({ details }) => {
  if (!details || Object.keys(details).length === 0) return null;

  const formatDetail = (detail) => {
    if (detail.type === "datetime") return toFullDate(new Date(detail.value));
    return detail.value;
  };

  return (
    <DataList.Root
      size="1"
      orientation={{ initial: "vertical", lg: "horizontal" }}
      style={{ rowGap: "8px" }}
    >
      {Object.entries(details).map(([title, detail]) => (
        <DataList.Item key={title}>
          <DataList.Label minWidth="100px" style={{ color: gray.gray9 }}>
            {title}
          </DataList.Label>
          <DataList.Value>
            {detail.value ? (
              <Text style={{ color: gray.gray11 }}>{formatDetail(detail)}</Text>
            ) : (
              <Text style={{ color: gray.gray8 }}>n/a</Text>
            )}
          </DataList.Value>
        </DataList.Item>
      ))}
    </DataList.Root>
  );
};

const Note = ({ note, currentUserId }) => {
  return (
    <Flex direction="column" gap="1" py="1">
      <Flex align="center" gap="6px">
        <Text size="1" weight="medium" style={{ color: gray.gray10 }}>
          {note.author.id === currentUserId
            ? "You"
            : `${note.author.first_name} ${note.author.last_name}`}
        </Text>
        <Tooltip content={prettifyDate(note.created_at, true)}>
          <Text size="1" style={{ color: gray.gray8, cursor: "default" }}>
            {toTimeAgo(note.created_at, true)}
          </Text>
        </Tooltip>
        {!note.is_public && (
          <Tooltip content="Private to you">
            <Lock size={11} color={gray.gray10} />
          </Tooltip>
        )}
      </Flex>
      <Text size="2" style={{ color: gray.gray12 }}>
        {note.note}
      </Text>
    </Flex>
  );
};

const SectionHeader = ({ title, icon: Icon, actionSlot }) => {
  return (
    <Flex
      align="center"
      justify="between"
      width="100%"
      mb="2"
      pb="2"
      pr="1"
      style={{ borderBottom: "1px solid var(--gray-4)" }}
    >
      <Flex align="center" gap="2">
        <Icon
          size={13}
          color={violet.violet9}
          strokeWidth={2}
          // style={{ opacity: 0.8 }}
        />
        <Text size="2" weight="medium" color="violet">
          {title}
        </Text>
      </Flex>
      {actionSlot && actionSlot}
    </Flex>
  );
};

const AccountSidebar = ({ account, onNoteAdded, onClose }) => {
  const { user } = useAuth();
  const { createAccountNote } = useAccount();
  const [isExpanded, setIsExpanded] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [isNotePublic, setIsNotePublic] = useState(false);

  const handleExpand = () => setIsExpanded(!isExpanded);

  const handleAddNote = () => {
    if (!noteText.trim()) return;

    // Create a temporary note object for immediate display
    const tempNote = {
      id: `temp-${Date.now()}`,
      note: noteText,
      created_at: new Date().toISOString(),
      is_public: isNotePublic,
      author: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
      },
    };

    // Call the parent's callback to update the account with the new note
    if (onNoteAdded) {
      onNoteAdded(tempNote);
    }

    // Make the actual API call in the background
    createAccountNote(account.id, noteText, isNotePublic);

    // Clear the input after submission
    setNoteText("");
    setIsNotePublic(false); // Reset to private by default
  };

  if (!account) return null;

  return (
    <Flex
      className="account-sidebar"
      direction="column"
      style={{
        height: "100%",
        width: "30%",
        minWidth: 380,
        maxWidth: 480,
        borderLeft: "1px solid var(--gray-6)",
        background: gray.gray2,
      }}
    >
      <Flex direction="column" pt="20px" pl="20px" pr="20px">
        <Flex justify="between" align="top">
          <Flex align="center" gap="6px">
            <Building2 size={12} color={gray.gray9} style={{ opacity: 0.8 }} />
            <Text size="1" weight="medium" style={{ color: gray.gray9 }}>
              Account
            </Text>
          </Flex>
          <Button
            variant="outline"
            color="gray"
            radius="large"
            onClick={onClose}
            style={{
              width: 24,
              height: 24,
              boxShadow: "none",
              border: "1px solid var(--gray-5)",
              padding: 0,
            }}
          >
            <X size={14} color={gray.gray9} />
          </Button>
        </Flex>

        <Text size="6" weight="medium" mt="2" mb="20px">
          {account.name}
        </Text>
      </Flex>

      <Separator
        size="4"
        style={{
          backgroundColor: "var(--gray-5)",
        }}
      />

      <ScrollArea>
        <Flex direction="column" gap="7" mx="20px" my="5">
          {/* Tasks */}
          {/* <Flex direction="column" gap="2">
            <SectionHeader
              title="Tasks"
              icon={ListTodo}
              actionSlot={
                <Button
                  size="1"
                  variant="soft"
                  color="gray"
                  radius="large"
                  style={{ height: 20 }}
                >
                  <Plus size={12} color={gray.gray9} /> Add
                </Button>
              }
            />
            <Text size="2">No tasks yet</Text>
          </Flex> */}

          {/* Notes */}
          <Flex direction="column" gap="2">
            <SectionHeader title="Notes" icon={NotebookPen} />
            <TextArea
              placeholder="Add a note..."
              size="2"
              variant="surface"
              color="gray"
              radius="large"
              resize="vertical"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              style={{
                width: "100%",
                background: "var(--gray-1)",
                boxShadow: "none",
                border: "1px solid var(--gray-4)",
              }}
            />

            <Flex justify="between" align="center" my="1">
              <Button
                size="1"
                radius="large"
                onClick={handleAddNote}
                disabled={!noteText.trim()}
              >
                <Plus size={12} /> Add note
              </Button>

              {/* <Flex align="center" gap="2">
                <Switch
                  size="1"
                  checked={isNotePublic}
                  onCheckedChange={setIsNotePublic}
                />
                <Flex align="center" gap="1">
                  <Users size={12} color={gray.gray11} />
                  <Text size="1" style={{ color: gray.gray11 }}>
                    Share with team
                  </Text>
                </Flex>
              </Flex> */}
            </Flex>

            {account.notes.length > 0 && (
              <Flex direction="column" gap="3" mt="2">
                {account.notes
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((note) => (
                    <Note key={note.id} note={note} currentUserId={user.id} />
                  ))}
              </Flex>
            )}
          </Flex>

          {/* Account details */}
          <Flex direction="column" gap="2">
            <SectionHeader title="Details" icon={Shapes} />
            <AdditionalDetails details={account.account_details} />
          </Flex>

          {/* Touchpoints */}
          <Flex direction="column" gap="2">
            <SectionHeader title="Touchpoints" icon={MessagesSquare} />
            <Flex direction="column" gap="5">
              <Flex direction="column" gap="1">
                <Text size="1" style={{ color: gray.gray9 }}>
                  Last touchpoint
                </Text>
                {account.touchpoints?.last_touchpoint ? (
                  <Touchpoint
                    touchpoint={account.touchpoints.last_touchpoint}
                  />
                ) : (
                  <Text size="2">No previous interactions</Text>
                )}
              </Flex>

              <Flex direction="column" gap="1">
                <Text size="1" style={{ color: gray.gray9 }}>
                  Next touchpoint
                </Text>
                {account.touchpoints?.next_touchpoint ? (
                  <Touchpoint
                    touchpoint={account.touchpoints.next_touchpoint}
                  />
                ) : (
                  <Text size="2">Nothing scheduled</Text>
                )}
              </Flex>
            </Flex>
          </Flex>

          {/* Monitor results */}
          <Flex direction="column" gap="2">
            <SectionHeader title="Monitor results" icon={Radar} />
            {account.recent_monitor_results?.length > 0 ? (
              <Flex direction="column" gap="2">
                {account.recent_monitor_results.map((result, index) => (
                  <MonitorBadge key={index} result={result} account={account} />
                ))}
              </Flex>
            ) : (
              <Text size="2">No results yet</Text>
            )}
          </Flex>

          {/* Recent activity */}
          <Flex direction="column" gap="2">
            <SectionHeader title="Recent activity" icon={Activity} />
            {account.recent_activity?.length > 0 ? (
              <ExpandableList
                items={account.recent_activity}
                renderItem={(activity, index) => (
                  <Flex
                    key={index}
                    align="top"
                    gap="2"
                    style={{ color: gray.gray12 }}
                  >
                    <Text style={{ fontSize: 10, marginTop: 1 }}>
                      {activity.emoji_label}
                    </Text>
                    <Text wrap="pretty" size="1">
                      {activity.insight}
                    </Text>
                  </Flex>
                )}
                emptyMessage="No highlights yet"
                isExpanded={isExpanded}
                onExpand={handleExpand}
              />
            ) : (
              <Text size="2">No recent activity</Text>
            )}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default AccountSidebar;
