import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { DropdownMenu, Text, Flex, TextField } from "@radix-ui/themes";
import { ExitIcon } from "@radix-ui/react-icons";
import { gray } from "@radix-ui/colors";
import {
  Building2,
  Plus,
  Radar,
  Flag,
  TextSearch,
  Folder,
  Check,
  Briefcase,
  AlertTriangle,
  BadgeAlert,
} from "lucide-react";

import { useAuth } from "./context/AuthContext";
import { useAccount } from "./context/AccountContext";
import { segment } from "./Analytics";
import ProductLogo from "./assets/product-logo.svg";
import "./Nav.css";

const UserAvatar = () => {
  const { user, logout } = useAuth();

  return (
    <DropdownMenu.Root size="1">
      <DropdownMenu.Trigger>
        <Flex justify="center" align="center" className="user-avatar">
          <Text weight="medium">{user.email.charAt(0).toUpperCase()}</Text>
        </Flex>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content variant="soft" align="end" sideOffset={8}>
        <DropdownMenu.Label size="1">
          <Text truncate={true} title={user.email} style={{ maxWidth: 172 }}>
            {user.email}
          </Text>
        </DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Item color="red" onClick={() => logout()}>
          <ExitIcon />
          Log out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const Link = ({
  to,
  title,
  icon: Icon,
  iconScale = 1,
  iconStrokeWidth,
  end,
  subnav = false,
  style,
  onClick,
}) => {
  return (
    <NavLink
      to={to}
      className="nav-link"
      end={end}
      draggable={false}
      style={style}
      onClick={onClick}
    >
      <Flex
        align="center"
        gap="10px"
        py={subnav ? "6px" : "2"}
        px="3"
        pl={subnav ? "20px" : "3"}
      >
        <Flex
          height={subnav ? "14px" : "16px"}
          // width={subnav ? "14px" : "16px"}
          align="center"
          justify="center"
        >
          <Icon
            size={subnav ? 14 : 16}
            color={gray.gray10}
            strokeWidth={iconStrokeWidth}
            style={{
              transform: `scale(${iconScale})`,
              transformOrigin: "center",
            }}
          />
        </Flex>
        <Text
          size={subnav ? "1" : "2"}
          weight="medium"
          style={{ color: subnav ? gray.gray11 : "inherit" }}
          truncate
        >
          {title}
        </Text>
      </Flex>
    </NavLink>
  );
};

const NewFolderInput = ({ onSubmit, onCancel }) => {
  const [folderName, setFolderName] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && folderName.trim()) {
      onSubmit(folderName.trim());
    } else if (e.key === "Escape") {
      onCancel();
    }
  };

  const handleSubmit = () => {
    if (folderName.trim()) {
      onSubmit(folderName.trim());
    }
  };

  return (
    <Flex
      align="center"
      gap="10px"
      py="6px"
      px="3"
      pl="4"
      className="nav-link"
      style={{ marginBottom: 4 }}
    >
      <Flex height="14px" align="center" justify="center">
        <Folder size={14} color={gray.gray10} strokeWidth={2} />
      </Flex>
      <TextField.Root
        size="1"
        variant="soft"
        color="gray"
        autoFocus
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={onCancel}
        placeholder="Folder name"
        style={{ width: "100%" }}
      >
        <TextField.Slot side="right">
          <Flex gap="1" align="center" mx="1">
            <Flex
              style={{
                cursor: folderName.trim() ? "pointer" : "not-allowed",
                color: folderName.trim() ? gray.gray10 : gray.gray7,
                opacity: folderName.trim() ? 1 : 0.5,
              }}
              onClick={folderName.trim() ? handleSubmit : undefined}
            >
              <Check size={14} color={gray.gray10} strokeWidth={2.5} />
            </Flex>
            {/* <Flex
              style={{ cursor: "pointer", color: gray.gray10 }}
              onClick={onCancel}
            >
              <X size={14} color={gray.gray10} strokeWidth={2.5} />
            </Flex> */}
          </Flex>
        </TextField.Slot>
      </TextField.Root>
    </Flex>
  );
};

const WorkingListLinks = () => {
  const { getWorkingList, createWorkingListSection } = useAccount();
  const [workingList, setWorkingList] = useState();
  const [isAddingFolder, setIsAddingFolder] = useState(false);

  useEffect(() => {
    getWorkingList().then(setWorkingList);
  }, [getWorkingList]);

  const handleAddFolder = (e) => {
    e.preventDefault();
    setIsAddingFolder(true);
  };

  const handleCreateFolder = async (folderName) => {
    try {
      await createWorkingListSection(folderName);
      const updatedList = await getWorkingList();
      setWorkingList(updatedList);
      setIsAddingFolder(false);

      segment.track("working_list_create_section", {
        section_name: folderName,
      });
    } catch (error) {
      console.error("Failed to create folder:", error);
    }
  };

  const handleCancelAddFolder = () => {
    setIsAddingFolder(false);
  };

  return (
    <>
      <Link
        to="/working-list/all"
        title="Working list"
        icon={Flag}
        iconStrokeWidth={2.1}
        end={true}
      />

      {workingList?.sections?.map((section) => (
        <Link
          key={section.id}
          to={`/working-list/${section.id}`}
          title={section.name}
          icon={Folder}
          iconStrokeWidth={2}
          subnav={true}
        />
      ))}

      {isAddingFolder && (
        <NewFolderInput
          onSubmit={handleCreateFolder}
          onCancel={handleCancelAddFolder}
        />
      )}

      <Link
        to={""}
        title={"New folder"}
        icon={Plus}
        iconStrokeWidth={2}
        subnav={true}
        style={{
          marginBottom: 4,
        }}
        onClick={handleAddFolder}
      />
    </>
  );
};

const Section = ({ title, children }) => {
  return (
    <Flex direction="column" gap="1">
      <Text
        size="1"
        weight="medium"
        ml="3"
        mt="3"
        mb="1"
        style={{ color: gray.gray10, userSelect: "none" }}
      >
        {title}
      </Text>
      {children}
    </Flex>
  );
};

const Nav = () => {
  const navigate = useNavigate();

  return (
    <nav>
      <Flex justify="between" align="center" pl="3" pr="1" mb="4">
        <img
          className="logo"
          src={ProductLogo}
          alt="Lateral"
          draggable={false}
          onClick={() => navigate("/")}
        />
        <UserAvatar />
      </Flex>
      <Flex direction="column" gap="1">
        <Section title="Accounts">
          <Link
            to={"/accounts/issues"}
            title={"Issues"}
            icon={BadgeAlert}
            iconScale={1.1}
            iconStrokeWidth={2}
          />
          {/* <WorkingListLinks /> */}
          <Link to="/accounts/my-book" title="My book" icon={Briefcase} />
          <Link to="/accounts/all" title="Directory" icon={Building2} />
        </Section>
        <Section title="Monitors">
          <Link to="/monitors/all" title="All" icon={Radar} />
          <Link
            to="/monitors/create"
            title="Create"
            icon={Plus}
            iconScale={1.15}
          />
        </Section>
      </Flex>
    </nav>
  );
};

export default Nav;
