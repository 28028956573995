import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Flex, Table, Text, Tooltip, Badge, Spinner } from "@radix-ui/themes";
import { gray, grayA } from "@radix-ui/colors";
import {
  ListChecks,
  SearchX,
  Radar,
  Lock,
  Users,
  Telescope,
  ClockAlert,
} from "lucide-react";

import { useMonitor, MONITOR_FREQUENCY_HOURS } from "./context/MonitorContext";
import { useHashScroll } from "./hooks/useHashScroll";
import DetectedBadge from "./DetectedBadge";
import EmptyState from "./EmptyState";
import Search from "./Search";
import SortableTable from "./SortableTable";
import Toolbar from "./Toolbar";
import { prettifyDate, toTimeAgo } from "./utils";
import "./MonitorDetail.css";

const AccessIcon = ({ access }) => {
  const iconProps = {
    size: 16,
    strokeWidth: 2,
    color: gray.gray8,
    style: { flexShrink: 0 },
  };

  const accessTypes = {
    private: {
      icon: <Lock {...iconProps} />,
      tooltip: `Private to you:
• Only runs on your accounts
• Won't be visible to teammates`,
    },
    shared: {
      icon: <Users {...iconProps} />,
      tooltip: `Shared with team:
• Runs on all accounts
• Visible to all teammates`,
    },
  };

  const type = accessTypes[access];
  if (!type) return null;

  return (
    <Tooltip
      content={type.tooltip}
      style={{
        whiteSpace: "pre-wrap",
      }}
    >
      {type.icon}
    </Tooltip>
  );
};

const ResultRow = ({
  result: { customer_account, detected, rationale, evidence, evaluated_at },
}) => {
  const isScrollTarget = useHashScroll(customer_account.id);
  const isOutdated =
    new Date(evaluated_at).getTime() <
    Date.now() - MONITOR_FREQUENCY_HOURS * 60 * 60 * 1000;

  return (
    <Table.Row
      className={`table-row ${isScrollTarget ? "highlighted" : ""} ${
        isOutdated ? "outdated" : ""
      }`}
      id={customer_account.id}
    >
      <Table.Cell>
        <Flex direction="column" gap="2" ml="3">
          <Text size="2" weight="medium">
            {customer_account.name}
          </Text>
          {isOutdated && (
            <Tooltip
              content={`Last updated ${toTimeAgo(
                new Date(evaluated_at).getTime(),
                true
              )}`}
            >
              <Badge color="gray" size="1" style={{ width: "fit-content" }}>
                <ClockAlert size={12} style={{ margin: "2px 0" }} />
                Outdated result
              </Badge>
            </Tooltip>
          )}
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <DetectedBadge detected={detected} />
      </Table.Cell>
      <Table.Cell pr="6">
        <Text size="2" color="gray">
          {rationale}
        </Text>
      </Table.Cell>
      <Table.Cell pr="5">
        <Text size="2" color="gray" style={{ whiteSpace: "pre-wrap" }}>
          {evidence}
        </Text>
      </Table.Cell>
    </Table.Row>
  );
};

const columns = [
  {
    key: "name",
    label: "Account",
    width: "20%",
    sortFn: (a, b) =>
      b.customer_account.name.localeCompare(a.customer_account.name),
  },
  {
    key: "detected",
    label: "Matched",
    width: "8%",
    sortFn: (a, b) => {
      if (a.detected !== b.detected) {
        return a.detected ? 1 : -1;
      }
      return b.customer_account.name.localeCompare(a.customer_account.name);
    },
  },
  {
    key: "rationale",
    label: "Rationale",
    width: "30%",
  },
  {
    key: "evidence",
    label: "Evidence",
    width: "30%",
  },
];

const ResultsTable = ({ results }) => (
  <SortableTable
    data={results}
    columns={columns}
    defaultSortKey="detected"
    rowRenderer={(result) => <ResultRow key={result.id} result={result} />}
    tableProps={{ className: "results-table" }}
    headerStyle={{ position: "sticky", top: 0 }}
  />
);

const CenteredContainer = ({ children }) => (
  <Flex
    align="center"
    justify="center"
    style={{ height: "calc(100vh - 144px)" }}
  >
    {children}
  </Flex>
);

const ResultsHeader = ({ results, searchTerm, onSearch, disabled }) => {
  const evaluatedAt = useMemo(() => {
    return results.length > 0
      ? results.reduce(
          (latest, result) =>
            Math.max(latest, new Date(result.evaluated_at).getTime()),
          0
        )
      : null;
  }, [results]);

  return (
    <Flex
      align="center"
      justify="between"
      px="5"
      style={{
        background: "var(--gray-2)",
        borderBottom: `1px solid ${grayA.grayA5}`,
        minHeight: 48,
        maxHeight: 48,
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <Flex align="center" gap="10px">
        <ListChecks size={16} color={gray.gray8} />
        <Text size="3" weight="medium">
          Latest run
        </Text>

        {evaluatedAt && (
          <Tooltip content={prettifyDate(evaluatedAt, true)}>
            <Badge
              size="1"
              variant="outline"
              color="gray"
              ml="1"
              style={{ opacity: 0.7, cursor: "default" }}
            >
              {toTimeAgo(evaluatedAt, true)}
            </Badge>
          </Tooltip>
        )}
      </Flex>

      <Search
        value={searchTerm}
        onChange={onSearch}
        disabled={disabled}
        style={{ height: 28 }}
      />
    </Flex>
  );
};

const Results = ({ results }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredResults = useMemo(() => {
    if (!results) return [];
    if (!searchTerm) return results;

    return results.filter((result) => {
      const searchableText = JSON.stringify({ ...result }).toLowerCase();
      return searchableText.includes(searchTerm.trim().toLowerCase());
    });
  }, [results, searchTerm]);

  const renderContent = () => {
    if (results.length === 0) {
      return (
        <CenteredContainer>
          <EmptyState
            title="Results coming soon..."
            description="Your monitor is set up and scheduled to run on your accounts tonight."
            icon={Telescope}
          />
        </CenteredContainer>
      );
    }

    if (filteredResults.length === 0) {
      return (
        <CenteredContainer>
          <EmptyState
            title="No matching accounts found"
            description="We didn't find any accounts that match your search term."
            icon={SearchX}
          />
        </CenteredContainer>
      );
    }

    return <ResultsTable results={filteredResults} />;
  };

  return (
    <Flex direction="column" style={{ width: "100%", height: "100%" }}>
      <ResultsHeader
        results={filteredResults}
        searchTerm={searchTerm}
        onSearch={setSearchTerm}
        disabled={results.length === 0}
      />
      {renderContent()}
    </Flex>
  );
};

const Loading = () => (
  <MonitorDetailContainer pageTitle="...">
    <Flex
      justify="center"
      align="center"
      style={{ height: "calc(100vh - 64px)" }}
    >
      <Flex direction="column" align="center" gap="4">
        <Spinner size="3" style={{ transform: "scale(1.5)" }} />
        <Text size="2" weight="medium" style={{ color: gray.gray10 }}>
          Loading...
        </Text>
      </Flex>
    </Flex>
  </MonitorDetailContainer>
);

const MonitorDetailContainer = ({ pageTitle, children }) => (
  <Flex className="monitor-detail-container" direction="column">
    <Toolbar
      icon={Radar}
      sectionTitle="Monitors"
      pageTitle={pageTitle}
      suffixSlot={<AccessIcon access={"private"} />}
    />
    <Flex
      direction="column"
      align="center"
      style={{
        minWidth: 860,
        margin: "0 auto",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </Flex>
  </Flex>
);

const MonitorDetail = () => {
  const { id } = useParams();
  const { getMonitor } = useMonitor();
  const [monitor, setMonitor] = useState(null);

  useEffect(() => {
    if (id) {
      getMonitor(id).then(setMonitor);
    }
  }, [id, getMonitor]);

  if (!monitor) return <Loading />;

  return (
    <MonitorDetailContainer pageTitle={monitor.label}>
      <Results results={monitor.recent_results} />
    </MonitorDetailContainer>
  );
};

export default MonitorDetail;
