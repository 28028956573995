import React, { createContext, useContext, useState } from "react";

const DevToolsContext = createContext();

const PREFIX = "_lateral_dev_tools_";

export const useDevTools = () => useContext(DevToolsContext);

export const DevToolsProvider = ({ children }) => {
  const [isEnabled, setIsEnabled] = useState(
    localStorage.getItem(`${PREFIX}enabled`) === "true" || false
  );
  const [bookOfBusinessUserId, setBookOfBusinessUserId] = useState(
    localStorage.getItem(`${PREFIX}book_of_business_user_id`) || null
  );

  const setBookOfBusiness = (userId) => {
    const key = `${PREFIX}book_of_business_user_id`;

    if (userId) {
      localStorage.setItem(key, userId);
    } else {
      localStorage.removeItem(key);
    }
    setBookOfBusinessUserId(userId);
  };

  return (
    <DevToolsContext.Provider
      value={{
        isEnabled,
        bookOfBusinessUserId: isEnabled ? bookOfBusinessUserId : null,
        setBookOfBusiness,
      }}
    >
      {children}
    </DevToolsContext.Provider>
  );
};
