import { useMemo } from "react";
import { Flex, Text, Badge } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import { ListFilter, ChevronDown } from "lucide-react";

import { useFuzzySearch } from "./Search";
import Select from "./Select";
import "./AccountFilters.css";

export const FILTER_TYPES = {
  LABEL: "label",
};

const filterPredicates = {
  [FILTER_TYPES.LABEL]: (account, labelId) =>
    labelId === "all" || account.labels.some((label) => label.id === labelId),
};

export const useAccountFiltering = (accounts = [], filters, searchTerm) => {
  const fuzzySearch = useFuzzySearch(accounts, [
    { name: "name", weight: 2 },
    // { name: "recent_activity.insight", weight: 1 },
  ]);

  return useMemo(() => {
    // First, apply filters
    const filtered = accounts.filter((account) =>
      filterPredicates[FILTER_TYPES.LABEL](account, filters[FILTER_TYPES.LABEL])
    );

    if (!searchTerm) return { results: filtered, textMatches: {} };

    // Apply search on top of filters
    const { results, textMatches } = fuzzySearch(searchTerm);
    return {
      results: results.filter((account) => filtered.includes(account)),
      textMatches,
    };
  }, [accounts, filters, searchTerm, fuzzySearch]);
};

const getFilterOptions = (accounts) => {
  if (!accounts?.length) return [];

  const labelCounts = accounts.reduce((acc, account) => {
    account.labels.forEach((label) => {
      acc.set(label.id, (acc.get(label.id) || 0) + 1);
    });
    return acc;
  }, new Map());

  // Get unique labels with counts, sorted alphabetically
  const uniqueLabels = Array.from(
    new Map(
      accounts.flatMap((account) =>
        account.labels.map((label) => [
          label.id,
          {
            value: label.id,
            label: label.name,
            description: label.description,
            count: labelCounts.get(label.id),
          },
        ])
      )
    ).values()
  ).sort((a, b) => a.label.localeCompare(b.label));

  return [
    {
      value: "all",
      label: "All",
      description: "Show all accounts",
      count: accounts.length,
    },
    ...uniqueLabels,
  ];
};

const FilterDropdown = ({ value, options, onChange, icon: Icon, disabled }) => {
  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <Select.Root
      className="filter-dropdown"
      value={value}
      onValueChange={onChange}
      disabled={!options?.length || disabled}
    >
      <Select.Trigger>
        <Flex className="trigger" align="center" gap="2" px="4" py="2">
          <Icon size={14} color={gray.gray9} style={{ opacity: 0.8 }} />
          <Text size="1" weight="medium" style={{ color: gray.gray11 }}>
            {selectedOption?.label || "All"}
          </Text>
          <Text
            size="1"
            weight="medium"
            style={{ color: gray.gray9, opacity: 0.8 }}
          >
            {selectedOption ? selectedOption.count : ""}
          </Text>
          <ChevronDown size={14} color={gray.gray9} />
        </Flex>
      </Select.Trigger>
      <Select.Content>
        {options.map(({ value, label, description, count }) => (
          <Select.Item key={value} value={value}>
            <Flex justify="between" align="center" gap="4">
              <Text title={description} wrap="nowrap">
                {label}
              </Text>
              <Badge
                size="1"
                variant="soft"
                color="gray"
                style={{ marginRight: -4 }}
              >
                {count}
              </Badge>
            </Flex>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

const AccountFilters = ({ accounts, filters, onFilter }) => {
  const filterOptions = useMemo(() => getFilterOptions(accounts), [accounts]);

  return (
    <Flex
      className="account-filters"
      justify="between"
      align="center"
      ml="2"
      style={{ backgroundColor: "#F7F7F9", borderRadius: 8 }}
    >
      <FilterDropdown
        value={filters[FILTER_TYPES.LABEL]}
        options={filterOptions}
        onChange={(value) => onFilter(FILTER_TYPES.LABEL, value)}
        icon={ListFilter}
        disabled={!accounts?.length}
      />
    </Flex>
  );
};

export default AccountFilters;
