import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Flex,
  Popover,
  ScrollArea,
  Table,
  Text,
  Tooltip,
  Spinner,
  Badge,
  Skeleton,
  TextField,
} from "@radix-ui/themes";
import { gray, grayA } from "@radix-ui/colors";
import {
  CircleHelp as HelpIcon,
  Rocket,
  Play,
  Database,
  ListChecks,
  ChevronDown,
  Filter as FilterIcon,
  Radar,
  Lock,
  Telescope,
  User,
  SearchX,
} from "lucide-react";
import TurndownService from "turndown";

import { useAuth } from "./context/AuthContext";
import { useMonitor } from "./context/MonitorContext";
import { useMonitorTesting } from "./hooks/useMonitorTesting";
import DetectedBadge from "./DetectedBadge";
import Select from "./Select";
import Search from "./Search";
import SortableTable from "./SortableTable";
import TextEditor from "./TextEditor";
import Toolbar from "./Toolbar";
import EmptyState from "./EmptyState";
import BG from "./assets/bg-accent.png";
import "./MonitorCreator.css";
import { segment } from "./Analytics";

const DeployPopover = ({
  label,
  setLabel,
  onDeploy,
  isDeploying,
  disabled,
}) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button size="2" radius="large" variant="soft" disabled={disabled}>
          <Rocket size={16} />
          Deploy
          <ChevronDown size={14} />
        </Button>
      </Popover.Trigger>
      <Popover.Content
        width="336px"
        size="2"
        align="end"
        sideOffset={10}
        style={{ overflow: "visible" }}
      >
        <Flex direction="column" gap="5" p="2">
          {/* Header */}
          <Flex direction="column" gap="3">
            <Text size="5" weight="medium">
              Deploy monitor
            </Text>

            <Text size="2" style={{ color: gray.gray10 }}>
              Once deployed, we'll continuously run your monitor and check for
              matching accounts.
            </Text>

            <Flex direction="column" gap="1" style={{ color: gray.gray10 }}>
              <Text size="2">Your new monitor:</Text>
              <Text as="ul" size="2" style={{ paddingLeft: "8px" }}>
                <li>Will not affect any underlying data</li>
                <li>Only runs on your accounts</li>
                <li>Won't be visible to teammates</li>
                <li>Can be turned off at any time</li>
              </Text>
            </Flex>
          </Flex>

          {/* Label input */}
          <Flex direction="column" gap="4">
            <Flex direction="column" gap="1">
              <Text size="2" weight="medium">
                Label{" "}
                <Text weight="regular" style={{ color: gray.gray10 }}>
                  (required)
                </Text>
              </Text>
              <TextField.Root
                size="2"
                placeholder="Enter a monitor label..."
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                radius="large"
              ></TextField.Root>
            </Flex>
          </Flex>

          {/* Footer */}
          <Flex justify="end" gap="3" mt="2">
            <Popover.Close>
              <Button variant="soft" size="2" radius="large" color="gray">
                Cancel
              </Button>
            </Popover.Close>
            <Button
              size="2"
              radius="large"
              onClick={onDeploy}
              disabled={isDeploying || !label?.trim()}
            >
              {isDeploying ? (
                <>
                  <Spinner />
                  Deploying...
                </>
              ) : (
                "Save & deploy"
              )}
            </Button>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};

const IntroHeader = ({ ...props }) => {
  return (
    <Flex
      direction="column"
      align="center"
      style={{ maxWidth: 680 }}
      {...props}
    >
      <Text
        as="h2"
        size="8"
        weight="medium"
        align="center"
        mt="5"
        mb="2"
        style={{ letterSpacing: "-1.5px" }}
      >
        Experiment & develop your monitor
      </Text>
      <Text size="4" align="center" style={{ color: gray.gray10 }}>
        Describe the account behavior to detect. Once deployed, we'll
        continuously analyze your selected data source and identify accounts
        that match.
      </Text>
    </Flex>
  );
};

const Trigger = ({
  icon: Icon,
  label,
  placeholder,
  count,
  disabled,
  className,
}) => {
  const CountBadge = ({ count }) => {
    return (
      <Flex
        align="center"
        px="1"
        style={{
          background: grayA.grayA3,
          borderRadius: 4,
        }}
      >
        <Text size="1" weight="medium" style={{ color: gray.gray10 }}>
          {count}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex
      className={`select-trigger ${className} ${disabled ? "disabled" : ""}`}
      align="center"
      gap="2"
      pl="3"
      pr="2"
      style={{
        color: label ? gray.gray12 : gray.gray11,
      }}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <Icon size={14} color={gray.gray8} style={{ marginRight: 2 }} />
      <Text size="2">{label || placeholder}</Text>
      {count ? <CountBadge count={count} /> : null}
      <ChevronDown size={14} color={gray.gray8} />
    </Flex>
  );
};

const DataSourceSelect = ({
  selectedRecordTypes,
  setSelectedRecordTypes,
  recordTypes,
  disabled,
}) => {
  const value =
    [...selectedRecordTypes.canonical, ...selectedRecordTypes.custom][0] || "";
  const selectedRecordType =
    [...recordTypes.canonical, ...recordTypes.custom].find(
      (s) => s.value === value
    ) || {};

  const handleValueChange = (value) => {
    const canonical = [];
    const custom = [];

    if (recordTypes.canonical.find((source) => source.value === value)) {
      canonical.push(value);
    }
    if (recordTypes.custom.find((source) => source.value === value)) {
      custom.push(value);
    }

    setSelectedRecordTypes({ custom, canonical });
  };

  return (
    <Select.Root
      value={selectedRecordType.value}
      onValueChange={handleValueChange}
    >
      <Select.Trigger>
        <Trigger
          icon={Database}
          label={selectedRecordType.name}
          placeholder="Choose data source"
          disabled={disabled}
        />
      </Select.Trigger>
      <Select.Content>
        {[...recordTypes.canonical, ...recordTypes.custom].map((source) => (
          <Select.Item key={source.value} value={source.value}>
            <Flex
              direction="column"
              gap="2px"
              style={{
                width: 232,
              }}
            >
              <Text>{source.name}</Text>
              <Text size="1" style={{ opacity: 0.5 }}>
                {source.description}
              </Text>
            </Flex>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

const Filter = ({ icon: Icon, title, value, options, onChange }) => {
  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  return (
    <Flex
      display={{ initial: "none", sm: "flex" }}
      style={{ userSelect: "none" }}
    >
      <Select.Root value={value} onValueChange={onChange}>
        <Select.Trigger>
          <Flex
            align="center"
            gap="6px"
            style={{
              border: `1px solid ${gray.gray4}`,
              borderRadius: 6,
              padding: "8px 12px",
              width: "100%",
            }}
          >
            <Icon size={12} color={grayA.grayA9} />
            {title && (
              <Text size="1" weight="medium" style={{ color: grayA.grayA9 }}>
                {title}
              </Text>
            )}
            <Text size="1" weight="medium" style={{ color: grayA.grayA11 }}>
              {selectedOption.label}
            </Text>
            <ChevronDown size={14} color={gray.gray9} />
          </Flex>
        </Select.Trigger>
        <Select.Content position="popper">
          {options.map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Flex>
  );
};

const Filters = ({ disabled, filters, onFilterChange }) => {
  const { user } = useAuth();

  const numFiltersSet = Object.values(filters).filter(
    (value) => value !== "all" && value !== null
  ).length;

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button style={{ all: "unset" }}>
          <Trigger
            icon={FilterIcon}
            title={numFiltersSet > 0 ? "Filters" : null}
            placeholder="Filters"
            count={numFiltersSet}
            disabled={disabled}
          />
        </button>
      </Popover.Trigger>
      <Popover.Content
        side="bottom"
        align="start"
        style={{
          backgroundColor: "white",
          borderRadius: 8,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          border: `1px solid ${gray.gray4}`,
          overflow: "visible",
        }}
      >
        <Flex direction="column" gap="3">
          <Text size="2" weight="medium">
            Account
          </Text>

          <Flex direction="column" gap="3">
            <Filter
              icon={User}
              title="Assigned to:"
              value={filters.assignedTo}
              options={[
                { value: null, label: "Anyone" },
                { value: user.id, label: "Myself" },
              ]}
              onChange={(value) =>
                onFilterChange({ ...filters, assignedTo: value })
              }
            />
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};

const EditorHeader = () => (
  <Flex
    align="center"
    justify="between"
    px="5"
    py="3"
    style={{
      borderBottom: `1px solid ${grayA.grayA4}`,
      margin: "0 -24px",
    }}
  >
    <Text size="3" weight="medium">
      What should Lateral check your accounts for?
    </Text>
    <Tooltip
      style={{ whiteSpace: "pre-wrap" }}
      content={`Be specific about:
• What behavior or change you're looking for
• Any relevant timeframes
• What indicates a meaningful pattern`}
    >
      <HelpIcon size={20} color={gray.gray8} />
    </Tooltip>
  </Flex>
);

const TestButton = ({ isTesting, onTest, disabled }) => {
  if (isTesting) {
    return (
      <Tooltip
        content="Analyzing your selected data source to detect matching accounts..."
        style={{ maxWidth: 240 }}
      >
        <Button size="2" radius="large" disabled>
          <Spinner />
          Testing...
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button size="2" radius="large" disabled={disabled} onClick={onTest}>
      <Play size={14} />
      Test
      <Flex
        align="center"
        ml="1"
        px="6px"
        py="2px"
        style={{
          background: "rgba(256, 256, 256, 0.1)",
          borderRadius: 4,
        }}
      >
        <Text size="1" weight="medium">
          ⌘{" "}
          <span
            style={{
              fontWeight: 400,
              opacity: 0.6,
            }}
          >
            +
          </span>{" "}
          ↵
        </Text>
      </Flex>
    </Button>
  );
};

const Editor = ({
  prompt,
  setPrompt,
  selectedRecordTypes,
  setSelectedRecordTypes,
  recordTypes,
  onTestMonitor,
  isTesting,
  filters,
  onFilterChange,
}) => {
  const hasPrompt = prompt.replace(/<[^>]*>/g, "").trim().length > 0;
  const hasDataSource =
    selectedRecordTypes.custom.length > 0 ||
    selectedRecordTypes.canonical.length > 0;
  const isFormValid = hasPrompt && hasDataSource;
  const isTestDisabled = !isFormValid || isTesting;

  // Test on Cmd+Enter or Ctrl+Enter
  useEffect(() => {
    const handleKeyDown = (e) => {
      const isShortcutPressed = (e.metaKey || e.ctrlKey) && e.key === "Enter";
      if (isShortcutPressed && !isTestDisabled) {
        onTestMonitor();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onTestMonitor, isTestDisabled]);

  return (
    <Flex className="editor" direction="column" gap="4" px="5" pb="20px">
      <EditorHeader />
      <TextEditor
        content={prompt}
        onChange={setPrompt}
        disabled={isTesting}
        placeholder="Detect accounts that have..."
      />

      <Flex justify="between" align="center" mt="2">
        <Flex align="center" gap="3">
          <DataSourceSelect
            selectedRecordTypes={selectedRecordTypes}
            setSelectedRecordTypes={setSelectedRecordTypes}
            recordTypes={recordTypes}
            disabled={isTesting}
          />
          <Filters
            disabled={isTesting}
            filters={filters}
            onFilterChange={onFilterChange}
          />
        </Flex>

        <TestButton
          isTesting={isTesting}
          onTest={onTestMonitor}
          disabled={isTestDisabled}
        />
      </Flex>
    </Flex>
  );
};

const LoadingRow = ({ accountName }) => {
  const placeholderText = {
    rationale:
      "No bill records were provided in the data sample, making it impossible to determine if any bills were uploaded today.",
    evidence:
      "The schema for Quickbooks Bills table was provided, but no actual bill records were included in the data to examine. This is likely indicative of an issue.",
  };

  return (
    <Table.Row className="table-row loading">
      <Table.Cell>
        <Flex align="center" gap="2" ml="3">
          <Text size="2" weight="medium">
            {accountName}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex align="center" gap="6px">
          <Spinner />
        </Flex>
      </Table.Cell>
      <Table.Cell pr="6">
        <Skeleton>{placeholderText.rationale}</Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton>{placeholderText.evidence}</Skeleton>
      </Table.Cell>
    </Table.Row>
  );
};

const ResultRow = ({ result: { account, monitor_answer } }) => {
  if (monitor_answer.loading) return <LoadingRow accountName={account.name} />;

  return (
    <Table.Row className="table-row">
      <Table.Cell>
        <Flex align="center" gap="2" ml="3">
          <Text size="2" weight="medium">
            {account.name}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <DetectedBadge {...monitor_answer} />
      </Table.Cell>
      <Table.Cell pr="6">
        <Text size="2" color={"gray"}>
          {monitor_answer.rationale}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text size="2" color="gray" style={{ whiteSpace: "pre-wrap" }}>
          {monitor_answer.evidence}
        </Text>
      </Table.Cell>
    </Table.Row>
  );
};

const columns = [
  {
    key: "name",
    label: "Account",
    width: "15%",
    sortFn: (a, b) => b.account.name.localeCompare(a.account.name),
  },
  {
    key: "detected",
    label: "Matched",
    width: "5%",
    sortFn: (a, b) => {
      if (a.monitor_answer.detected !== b.monitor_answer.detected) {
        return a.monitor_answer.detected ? 1 : -1;
      }
      return b.account.name.localeCompare(a.account.name);
    },
  },
  {
    key: "rationale",
    label: "Rationale",
    width: "30%",
  },
  {
    key: "evidence",
    label: "Evidence",
    width: "30%",
  },
];

const ResultsTable = ({ results }) => (
  <SortableTable
    data={results}
    columns={columns}
    defaultSortKey="name"
    rowRenderer={(result) => (
      <ResultRow key={result.account.id} result={result} />
    )}
  />
);

const ResultsHeader = ({ results, searchTerm, onSearch, disabled }) => (
  <Flex
    align="center"
    justify="between"
    px="5"
    style={{
      background: "var(--gray-a2)",
      borderTop: `1px solid ${grayA.grayA5}`,
      borderBottom: `1px solid ${grayA.grayA5}`,
      minHeight: 48,
      maxHeight: 48,
    }}
  >
    <Flex align="center" gap="10px">
      <ListChecks size={18} color={gray.gray8} />
      <Text size="4" weight="medium">
        Results
      </Text>
      {Array.isArray(results) && (
        <Tooltip
          content="Testing runs on a small, random sample of your accounts. Once your monitor is deployed, it'll run on all your accounts."
          style={{ maxWidth: 248 }}
        >
          <Badge
            size="1"
            variant="outline"
            color="gray"
            ml="1"
            style={{ opacity: 0.7, cursor: "default" }}
          >
            Sample
          </Badge>
        </Tooltip>
      )}
    </Flex>
    <Search
      value={searchTerm}
      onChange={onSearch}
      disabled={disabled}
      style={{ height: 28 }}
    />
  </Flex>
);

const Results = ({ results, isTesting, searchTerm, onSearch }) => {
  const hasNotTested = results == null;

  const filteredResults = useMemo(() => {
    if (!results) return [];
    if (!searchTerm) return results;

    return results.filter((result) => {
      const searchableText = JSON.stringify({ ...result }).toLowerCase();
      return searchableText.includes(searchTerm.trim().toLowerCase());
    });
  }, [results, searchTerm]);

  const renderContent = () => {
    if (hasNotTested) {
      return (
        <EmptyState
          title="No results yet"
          description="Test your monitor to get started"
          icon={Telescope}
        />
      );
    }

    if (filteredResults.length === 0) {
      return (
        <EmptyState
          title="No matching accounts found"
          description="We didn't find any accounts that match your search term."
          icon={SearchX}
        />
      );
    }

    return <ResultsTable results={filteredResults} />;
  };

  return (
    <Flex direction="column" style={{ width: "100%", height: "100%" }}>
      <ResultsHeader
        results={results}
        searchTerm={searchTerm}
        onSearch={onSearch}
        disabled={hasNotTested || isTesting}
      />
      {renderContent()}
    </Flex>
  );
};

const HeroContainer = ({ children }) => {
  return (
    <Flex
      direction="column"
      align="center"
      gap="4"
      pt="8"
      pb="8"
      style={{
        width: "100%",
        background: `url(${BG}), linear-gradient(302deg, #F5F2FF 4.01%, #FDFCFE 90.89%)`,
        backgroundPosition: "right calc(100% + 396px), center",
        backgroundSize: "calc(535px * 1.125) calc(984px * 1.125), 100% 100%",
        backgroundBlendMode: "darken, normal",
        backgroundRepeat: "no-repeat, no-repeat",
      }}
    >
      {children}
    </Flex>
  );
};

const MonitorCreatorContainer = ({ toolbarAction, children }) => {
  return (
    <Flex className="monitor-creator-container" direction="column">
      <Toolbar
        icon={Radar}
        sectionTitle="Monitors"
        pageTitle="Untitled"
        suffixSlot={
          <Tooltip
            content={`Private to you:
• Only runs on your accounts
• Won't be visible to teammates`}
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            <Lock size={16} strokeWidth={2} color={gray.gray8} />
          </Tooltip>
        }
        primaryAction={toolbarAction}
      />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 79px)" }}
      >
        <Flex
          direction="column"
          align="center"
          style={{
            minWidth: 860,
            margin: "0 auto",
            width: "100%",
            height: "100%",
          }}
        >
          {children}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

const turndownService = new TurndownService({
  bulletListMarker: "-",
  codeBlockStyle: "fenced",
});

const convertHtmlToMarkdown = (html) => {
  if (!html) return "";
  return turndownService.turndown(html);
};

const MonitorCreator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getRecordTypes, createMonitor } = useMonitor();
  const { results, isTesting, testMonitor, resetResults } = useMonitorTesting();

  // Editor state
  const [label, setLabel] = useState("");
  const [prompt, setPrompt] = useState("");
  const [recordTypes, setRecordTypes] = useState({ custom: [], canonical: [] });
  const [selectedRecordTypes, setSelectedRecordTypes] = useState({
    custom: [],
    canonical: [],
  });
  const [filters, setFilters] = useState({ assignedTo: null });

  // Page state
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);

  useEffect(() => {
    getRecordTypes().then(setRecordTypes);
  }, []);

  // Reset creator when clicking "Create" in sidenav
  useEffect(() => {
    if (location.pathname === "/monitors/create") {
      resetCreator();
    }
  }, [location]);

  const handleDeployMonitor = async () => {
    setIsDeploying(true);
    await createMonitor(
      label,
      convertHtmlToMarkdown(prompt),
      selectedRecordTypes.custom[0],
      selectedRecordTypes.canonical[0]
    );

    segment.track("monitor_deploy", {
      label,
      prompt,
      record_types: selectedRecordTypes,
    });
    setIsDeploying(false);

    navigate(`/monitors`);
  };

  const handleTestMonitor = async () => {
    setSearchTerm("");
    await testMonitor(
      convertHtmlToMarkdown(prompt),
      selectedRecordTypes,
      filters
    );

    segment.track("monitor_test", {
      prompt,
      record_types: selectedRecordTypes,
      filters,
    });
  };

  const resetCreator = () => {
    setPrompt("");
    setSelectedRecordTypes({ custom: [], canonical: [] });
    resetResults();
    setFilters({ assignedTo: null });
    setSearchTerm("");
  };

  return (
    <MonitorCreatorContainer
      toolbarAction={
        <DeployPopover
          label={label}
          setLabel={setLabel}
          onDeploy={handleDeployMonitor}
          isDeploying={isDeploying}
          disabled={!results}
        />
      }
    >
      <HeroContainer>
        {!results && <IntroHeader mb="6" />}
        <Editor
          prompt={prompt}
          setPrompt={setPrompt}
          recordTypes={recordTypes}
          selectedRecordTypes={selectedRecordTypes}
          setSelectedRecordTypes={setSelectedRecordTypes}
          onTestMonitor={handleTestMonitor}
          isTesting={isTesting}
          filters={filters}
          onFilterChange={setFilters}
        />
      </HeroContainer>
      <Results
        results={results}
        isTesting={isTesting}
        searchTerm={searchTerm}
        onSearch={setSearchTerm}
      />
    </MonitorCreatorContainer>
  );
};

export default MonitorCreator;
