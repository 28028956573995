import React, { createContext, useCallback, useContext } from "react";
import { BASE_URL } from "../App";

const MonitorContext = createContext();

// TODO: fetch from backend
export const MONITOR_FREQUENCY_HOURS = 24;

export const useMonitor = () => {
  return useContext(MonitorContext);
};

export const MonitorProvider = ({ children }) => {
  const getMonitors = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/api/v1/monitors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();
    return data.monitors;
  }, []);

  const getMonitor = useCallback(async (id) => {
    const response = await fetch(`${BASE_URL}/api/v1/monitors/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();
    return data;
  }, []);

  const getRecordTypes = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/api/v1/record-types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();
    return data;
  }, []);

  const testDraftMonitor = useCallback(
    async (
      prompt,
      customRecordType,
      canonicalRecordType,
      customerAccountId,
      lookbackDays = 7
    ) => {
      const response = await fetchWithTimeout(
        `${BASE_URL}/api/v1/monitors/drafts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt,
            custom_record_definition_id: customRecordType,
            canonical_record_type: canonicalRecordType,
            customer_account_id: customerAccountId,
            lookback_days: lookbackDays,
          }),
          credentials: "include",
        }
      );

      const data = await response.json();
      return data;
    },
    []
  );

  const createMonitor = useCallback(
    async (
      label,
      prompt,
      customRecordType,
      canonicalRecordType,
      lookbackDays = 7
    ) => {
      const response = await fetch(`${BASE_URL}/api/v1/monitors`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          label,
          prompt,
          custom_record_definition_id: customRecordType,
          canonical_record_type: canonicalRecordType,
          lookback_days: lookbackDays,
        }),
        credentials: "include",
      });

      const data = await response.json();
      return data;
    },
    []
  );

  const exportMonitorResults = useCallback(async (monitorIds, lookbackDays) => {
    const url = new URL(`${BASE_URL}/api/v1/monitors/export/csv`);

    if (monitorIds && monitorIds.length > 0) {
      monitorIds.forEach((id) => url.searchParams.append("monitor_id", id));
    }
    if (lookbackDays) url.searchParams.append("lookback_days", lookbackDays);

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a hidden anchor element
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = downloadUrl;

      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition
        .split("filename=")[1]
        .replace(/"/g, "");

      a.download = filename;

      // Trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);

      return true;
    } catch (error) {
      console.error("Download failed:", error);
      throw error;
    }
  }, []);

  return (
    <MonitorContext.Provider
      value={{
        getMonitors,
        getMonitor,
        getRecordTypes,
        testDraftMonitor,
        createMonitor,
        exportMonitorResults,
      }}
    >
      {children}
    </MonitorContext.Provider>
  );
};

const withTimeout = async (fetchFn, timeoutMs = 30000) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeoutMs);

  try {
    const result = await fetchFn(controller.signal);
    clearTimeout(timeoutId);
    return result;
  } catch (error) {
    clearTimeout(timeoutId);
    throw error;
  }
};

export const fetchWithTimeout = (url, options = {}, timeoutMs = 30000) => {
  return withTimeout(async (signal) => {
    const response = await fetch(url, { ...options, signal });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }, timeoutMs);
};
